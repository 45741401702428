import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { FaArrowsAltH, FaEye } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

import {
  Box,
  Flex,
  Button,
  Skeleton,
  Text,
  FormLabel,
  Switch,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core/typings/types';
import { Form } from '@unform/web';
import axios from 'axios';
import { subDays, format } from 'date-fns';
import moment from 'moment';
import queryString from 'query-string';
import * as Yup from 'yup';

import {
  CardGraphicDepartmentPeriod,
  DepartmentPeriod,
  DepartmentChartPeriod,
} from '~/shared/components/CardGraphicDepartment';
import RangeDateWithTwoInput from '~/shared/components/RangeDateWithTwoInput';
import SectionHeader from '~/shared/components/SectionHeader';
import api from '~/shared/services/api';
import selectTheme from '~/utils/getThemeGraphics';
import getValidationErrors from '~/utils/getValidationErrors';

import DoughnutChart from '../../components/Doughnut';
import ToggleCustom from '../../components/ToggleCustom';
import { ContainerCards } from '../AnalyzeActivities/styles';
import { Card } from './AllocationDepartment/styles';

interface AllocationGraphProps {
  alocacaoDepartamentos: DepartmentChartPeriod[];
}

interface TeamAllocation {
  alocacao: number;
  alocacao_prevista: number;
  alocacao_geral: number;
  alocacao_prevista_geral: number;
}

interface TotalAlloc {
  alocacao_real_percent: number;
  total_alocacao_real_percent: number;
  alocacao_prevista_percent: number;
  total_alocacao_prevista_percent: number;
}

interface Responsible {
  id: number;
  email: string;
  avatar: string;
  avatar_old_name: string;
  cover: string;
  cover_old_name: string;
  google_id: string;
  google_image: string;
  senha_recuperar: string;
  email_confirmar: string;
  imagem: string;
  status_id: number;
  created_at: string;
  updated_at: string;
  name: string;
}

interface FormData {
  date: string;
}

interface DateAllocation {
  start_date: string;
  end_date: string;
  adjust?: number;
}

export interface QueryParamsAllocation {
  start_date?: string;
  end_date?: string;
  adjust?: string;
}

const AllocationGraph: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const location = useLocation();

  const queryParams = useMemo<QueryParamsAllocation>(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const [loading, setLoading] = useState(true);
  const [loadingAllocation, setLoadingAllocation] = useState(true);
  // const [loadingDepartment, setLoadingDepartment] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(true);
  const [
    toggleAdjustExpectedAllocation,
    setToggleAdjustExpectedAllocation,
  ] = useState(() => !queryParams?.adjust || queryParams?.adjust === '1');

  const [time, setTime] = useState<DateAllocation>(() => {
    const currentDate = new Date();

    return {
      start_date:
        queryParams.start_date ?? format(subDays(currentDate, 7), 'yyyy-MM-dd'),
      end_date: queryParams.end_date ?? format(currentDate, 'yyyy-MM-dd'),
      adjust: toggleAdjustExpectedAllocation ? 1 : 0,
    };
  });

  /* const [dateFilter, setDateFilter] = useState<DateAllocation>((): any => {
    return {
      start_date: queryParams.start_date
        ? queryParams.start_date
        : time.start_date,
      end_date: queryParams.end_date ? queryParams.end_date : time.end_date,
    };
  }); */

  /* const dateFilter = useMemo(() => {
    return {
      start_date: queryParams.start_date
        ? queryParams.start_date
        : time.start_date,
      end_date: queryParams.end_date ? queryParams.end_date : time.end_date,
    };
  }, [time, queryParams]); */

  const [departments, setDepartment] = useState<DepartmentChartPeriod[]>([]);
  const [totalAllocation, setTotalAllocation] = useState<number[]>([]);
  const [realAllocation, setRealAlocation] = useState<number[]>([]);
  const allocationTotal = useMemo(() => {
    return {
      labels: [
        'Alocação Prevista',
        'Alocação Prevista Restante',
        'Alocação Realizada',
        'Alocação Realizada Restante',
      ],
      dataLabels: [
        'allocation_prevista',
        'allocation_prev',
        'allocation_real',
        'allocation',
      ],
    };
  }, []);

  const formatCurrentDate = format(new Date(), 'yyyy-MM-dd');
  const currentDate = new Date();
  const startDate = subDays(currentDate, 7);
  const formatStartDate = format(subDays(currentDate, 7), 'yyyy-MM-dd');

  const loadAllocation = useCallback(
    async (source: any) => {
      setLoadingAllocation(true);
      await api
        .get<TotalAlloc>('allocation', {
          cancelToken: source.token,
          params: {
            start_date: time.start_date,
            end_date: time.end_date,
            adjust_expected_allocation: toggleAdjustExpectedAllocation ? 1 : 0,
          },
        })
        .then((response) => {
          const {
            alocacao_prevista_percent,
            total_alocacao_prevista_percent,
            alocacao_real_percent,
            total_alocacao_real_percent,
          } = response.data;

          const alloc_prev = alocacao_prevista_percent
            ? alocacao_prevista_percent.toFixed(1)
            : 0;
          const alloc_real = alocacao_real_percent
            ? alocacao_real_percent.toFixed(1)
            : 0;

          const total_prev = total_alocacao_prevista_percent
            ? total_alocacao_prevista_percent.toFixed(1)
            : 0;
          const total_real = total_alocacao_real_percent
            ? total_alocacao_real_percent.toFixed(1)
            : 0;
          // console.log(alloc_prev, alloc_real, total_prev, total_real);
          setTotalAllocation([Number(alloc_prev), Number(total_prev)]);

          setRealAlocation([Number(alloc_real), Number(total_real)]);
        })
        .finally(() => setLoadingAllocation(false));
    },
    [time, toggleAdjustExpectedAllocation],
  );
  const loadAllocationTeams = useCallback(
    async (source: any) => {
      setLoading(true);
      await api
        .get<AllocationGraphProps>('allocation/department', {
          cancelToken: source.token,
          params: {
            start_date: time.start_date,
            end_date: time.end_date,
            adjust_expected_allocation: toggleAdjustExpectedAllocation ? 1 : 0,
          },
        })
        .then((response) => {
          const { alocacaoDepartamentos } = response.data;

          setDepartment(
            alocacaoDepartamentos.map((alloc) => {
              const dataLabels = allocationTotal.dataLabels.map(
                (lab) => `${lab}-${alloc.departamento_id}`,
              );
              const alloc_prev = alloc.alocacao_prevista_geral?.toFixed(1);
              const total_alloc_prev = alloc.total_alocacao_prevista_geral?.toFixed(
                1,
              );
              const alloc_geral = alloc.alocacao_geral?.toFixed(1);
              const total_alloc_geral = alloc.total_alocacao_geral?.toFixed(1);
              return {
                ...alloc,
                dataChart: {
                  options: { legend: { display: false } },
                  datasets: [
                    {
                      hoverBorderColor: '#eef5ff',
                      borderWidth: [5, 5],
                      borderColor: '#eef5ff',
                      datalabels: {
                        display: false,
                        // align: 'start',
                        // padding: { right: 70 },
                        // color: '#090979',
                        // font: { size: 16 },
                      },
                      // borderColor: '#fed7d750',
                      data: [
                        Number(alloc_prev),
                        Number(total_alloc_prev),
                        null,
                        null,
                      ],
                      // weight: 0.7,
                    },
                    {
                      borderWidth: [5, 5],
                      borderColor: '#eef5ff',
                      datalabels: {
                        display: [false, false, false, false],
                        // align: 'start',
                        // padding: { right: 100 },
                        // color: '#00bfff',
                        // textStrokeWidth: 10,
                        // font: { size: 16 },
                      },

                      // borderColor: '#fed7d750',
                      data: [
                        null,
                        null,
                        Number(alloc_geral),
                        Number(total_alloc_geral),
                      ],
                      // weight: 1,
                    },
                  ],

                  labels: allocationTotal.labels,
                  dataLabels,
                },
                /* teams: alloc.subordinate_departments?.map((team) => {
                return {
                s fakes para teste
                  ...team,
                  alocacao: team.alocacao,
                  alocacao_prevista: team.alocacao_prevista,
                  alocacao_geral: team.alocacao_geral,
                  alocacao_prevista_geral: team.alocacao_prevista_geral,
                };
              }), */
                subordinate_departments: alloc.subordinate_departments?.map(
                  (depSub) => {
                    const dataLabelsSub = allocationTotal.dataLabels.map(
                      (lab) => `${lab}-${depSub.id}`,
                    );
                    const alloc_sub = depSub.alocacao?.toFixed(1);
                    const total_alloc_sub = depSub.total_alocacao?.toFixed(1);
                    const alloc_sub_prev = depSub.alocacao_prevista?.toFixed(1);
                    const total_alloc_sub_prev = depSub.total_alocacao_prevista?.toFixed(
                      1,
                    );
                    return {
                      ...depSub,
                      dataChart: {
                        options: {
                          legend: { display: false },
                        },
                        datasets: [
                          {
                            hoverBorderColor: '#eef5ff',
                            borderWidth: [6, 6],
                            borderColor: '#eef5ff',
                            datalabels: {
                              display: [false, false, false, false],
                              // align: 'start',
                              // padding: { right: 70 },
                              // color: '#090979',
                              // font: { size: 15 },
                            },
                            // borderColor: ['transparent', 'transparent'],
                            data: [
                              Number(alloc_sub_prev),
                              Number(total_alloc_sub_prev),
                              null,
                              null,
                            ],

                            align: 'end',
                            // weight: 0.7,
                          },
                          {
                            borderWidth: [6, 6],
                            borderColor: '#eef5ff',
                            datalabels: {
                              display: [false, false, false, false],
                              // align: 'start',
                              // padding: { right: 90 },
                              // color: '#00bfff',
                              // font: { size: 15 },
                            },
                            // borderWidth: 5,
                            // borderColor: ['transparent', 'transparent'],
                            data: [
                              null,
                              null,
                              Number(alloc_sub),
                              Number(total_alloc_sub),
                            ],
                            align: 'end',
                            // weight: 1,
                          },
                        ],
                        labels: allocationTotal.labels,
                        dataLabels: allocationTotal.dataLabels,
                      },
                    };
                  },
                ),
              };
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [allocationTotal, time, toggleAdjustExpectedAllocation],
  );

  /*  const getDepartmentPeriod = useCallback(
    async (source: any) => {
      setLoading(true);

      await api
        .get<DepartmentPeriod>('allocation/department', {
          cancelToken: source.token,
          params: {
            start_date: time.start_date,
            end_date: time.end_date,
            adjust_expected_allocation: toggleAdjustExpectedAllocation ? 1 : 0,
          },
        })
        .then((response) => {
          const { data } = response;

          setDepartmentPeriod(data);

        })
        .finally(() => setLoading(false));
    },
    [time, toggleAdjustExpectedAllocation],
  ); */

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    setTotalAllocation([]);
    setRealAlocation([]);
    setDepartment([]);

    loadAllocation(source);

    loadAllocationTeams(source);

    // console.log(departments, 'teste');

    return () => {
      /* Após chamar novamente a api, sem que se tenha resposta da primeira solicitação (pendente)
            o pedido anterior é cancelado e se realiza um novo pedido */

      source.cancel('Operação cancelada pelo usuário');
    };
  }, [loadAllocation, loadAllocationTeams]);

  const handleDateSubmit = useCallback(
    async (data: FormData): Promise<void> => {
      // console.log(data);
      setLoadingSubmit(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          date: Yup.array()
            .of(Yup.string().required('Data obrigatoria'))
            .required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const dataTimeAllocation: DateAllocation = {
          end_date: data.date[1],
          start_date: data.date[0],
        };

        const startDateArr = data.date[0]
          .split('/')
          .map((dateStr) => Number(dateStr));
        const endDateArr = data.date[1]
          .split('/')
          .map((dateStr) => Number(dateStr));

        const formatedDate = {
          start_date: format(
            new Date(startDateArr[2], startDateArr[1] - 1, startDateArr[0]),
            'yyyy-MM-dd',
          ),
          end_date: format(
            new Date(endDateArr[2], endDateArr[1] - 1, endDateArr[0]),
            'yyyy-MM-dd',
          ),
          adjust: toggleAdjustExpectedAllocation ? 1 : 0,
        };
        setTime(formatedDate);
        // setDateFilter(formatedDate);
        /* await loadDateAllocation({
          start_date: format(
            new Date(startDateArr[2], startDateArr[1] - 1, startDateArr[0]),
            'yyyy-MM-dd',
          ),
          end_date: format(
            new Date(endDateArr[2], endDateArr[1] - 1, endDateArr[0]),
            'yyyy-MM-dd',
          ),
        });
        await loadDateAllocationTeams({
          start_date: format(
            new Date(startDateArr[2], startDateArr[1] - 1, startDateArr[0]),
            'yyyy-MM-dd',
          ),
          end_date: format(
            new Date(endDateArr[2], endDateArr[1] - 1, endDateArr[0]),
            'yyyy-MM-dd',
          ),
        }); */
        setLoadingSubmit(false);
      } catch (err) {
        // console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          if (errors[`date[0]`]) {
            errors.time = 'Período obrigatório';
          }

          formRef.current?.setErrors(errors);
        }
      }
    },
    [toggleAdjustExpectedAllocation],
  );

  /* const handleAllocationDepartment = (departmentId: number): string => {
    const date = {
      ...time,
      start_date: time
        ? format(parseISO(time.start_date), 'yyyy-MM-dd')
        : format(startDate, 'yyyy-MM-dd'),
      end_date: time
        ? format(parseISO(time.end_date), 'yyyy-MM-dd')
        : format(currentDate, 'yyyy-MM-dd'),
    };

    return `/relatorios/alocacao/${departmentId}?start_date=${date.start_date}&end_date=${date.end_date}`;
  }; */

  // console.log(time);
  return (
    <Box>
      <SectionHeader
        title="Alocação de Equipes"
        pagename="Alocação de equipes"
        goBackLink="/dashboard"
      >
        {/* {loading || loadingAllocation ? (
          <Skeleton height="32px" width="260px" />
        ) : ( */}
        <ToggleCustom
          defaultIsChecked={toggleAdjustExpectedAllocation}
          toogleAdjust={() =>
            setToggleAdjustExpectedAllocation((state) => !state)
          }
          loading={loading || loadingAllocation}
          tittle="Ajustar alocação prevista"
          id="adjustExpectedAllocation"
        />
      </SectionHeader>

      <>
        <Flex
          flexDir={['column', 'row']}
          justifyContent="center"
          alignItems="center"
        >
          <Flex
            flexDir={['column', 'row']}
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            {!loadingAllocation && !loading && (
              <Flex
                position="absolute"
                right={['125px', '211px']}
                top={['240px', '123px']}
              >
                <Flex flexDir="column">
                  <Text as="b" fontSize={['md', '2xl']} color="#090979">
                    {totalAllocation[0]}%
                  </Text>
                  <Text as="b" fontSize={['md', '2xl']} color="#00bfff">
                    {realAllocation[0]}%
                  </Text>
                </Flex>
              </Flex>
            )}
            <Form
              onSubmit={handleDateSubmit}
              ref={formRef}
              initialData={{
                date: [
                  moment(
                    queryParams.start_date
                      ? queryParams.start_date
                      : formatStartDate,
                  ),
                  moment(
                    queryParams.end_date
                      ? queryParams.end_date
                      : formatCurrentDate,
                  ),
                ],
              }}
            >
              <RangeDateWithTwoInput name="date" />

              <Flex justifyContent="flex-start">
                <Button
                  disabled={loading}
                  colorScheme="blue"
                  size="lg"
                  onClick={() => {
                    formRef.current?.submitForm();
                  }}
                  px={4}
                  mx="auto"
                  minWidth="300px"
                >
                  Filtrar
                </Button>
              </Flex>
            </Form>

            {loading || loadingAllocation ? (
              <Box
                height={['270px', '415px']}
                width={['270px', '480px']}
                my={3}
                ml={3}
              >
                <Flex
                  flexDir="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Flex
                    bg="#f7fafc"
                    height="35px"
                    width={['200px', '400px']}
                    mt={5}
                    mb={4}
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="8px"
                  >
                    <Text color="gray.500" bg="#f7fafc" textAlign="center">
                      Alocação Geral
                    </Text>
                  </Flex>
                  <Flex
                    position="relative"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Skeleton
                      color="white"
                      width={['100px', '200px']}
                      height={['100px', '200px']}
                      rounded="100%"
                    />
                    <Box
                      bgColor="#fff"
                      position="absolute"
                      width={['60px', '100px']}
                      height={['60px', '100px']}
                      rounded="100%"
                    />
                  </Flex>

                  <Skeleton
                    width={['150px', '300px']}
                    height={['16px', '25px']}
                    mt={3}
                  />
                  <Skeleton
                    width={['100px', '300px']}
                    height={['16px', '25px']}
                    mt={3}
                  />
                </Flex>
              </Box>
            ) : (
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDir="row-reverse"
                width={['300px', '500px']}
                mb={5}
                mt={[5, 0]}
                zIndex={1000}
              >
                <DoughnutChart
                  colors={selectTheme('alocation')}
                  data={{
                    datasets: [
                      {
                        data: [...totalAllocation, null, null],
                        label: 'Dataset 1',
                      },
                      {
                        data: [null, null, ...realAllocation],
                        label: 'Dataset 2',
                      },
                    ],
                    title: 'Alocação Geral',
                    labels: allocationTotal.labels,
                    dataLabels: allocationTotal.dataLabels,
                  }}
                  height={75}
                  selectChart={() => ''}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
        {loading ? (
          <Flex
            flexDir={['column', 'row']}
            justifyContent="center"
            alignItems="center"
          >
            <Box
              background="#eef5ff"
              width={['260px', '500px']}
              height={['650px', '560px']}
              p="20px"
              borderRadius="8px"
            >
              <Flex
                flexDir={['column', 'row']}
                justifyContent={['', 'space-between']}
                alignItems="center"
                ml={[0, 6]}
              >
                <Flex
                  flexDir="column"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Flex alignItems="center">
                    <Skeleton
                      width="50px"
                      height="50px"
                      rounded="100%"
                      mr={3}
                    />
                    <Skeleton width="100px" height="20px" />
                  </Flex>
                  <Box>
                    <Skeleton width="210px" height="20px" my={3} />
                    <Skeleton
                      display={['none', 'inherit']}
                      width="130px"
                      height="20px"
                    />
                  </Box>
                </Flex>
                <Flex
                  position="relative"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Skeleton
                    color="white"
                    width={['150px', '200px']}
                    height={['150px', '200px']}
                    rounded="100%"
                  />
                  <Box
                    bgColor="#eef5ff"
                    position="absolute"
                    width={['90px', '100px']}
                    height={['90px', '100px']}
                    rounded="100%"
                  />
                </Flex>
              </Flex>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems={['center', 'start']}
                ml={[0, 6]}
              >
                <Skeleton width="130px" height="40px" mb={6} mt={3} />
                <Flex
                  justifyContent="space-between"
                  flexDir={['column', 'row']}
                >
                  <Flex
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                    mr={[0, '50px']}
                  >
                    <Flex
                      position="relative"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Skeleton
                        color="white"
                        width={['100px', '180px']}
                        height={['100px', '180px']}
                        rounded="100%"
                      />
                      <Box
                        bgColor="#eef5ff"
                        position="absolute"
                        width={['60px', '100px']}
                        height={['60px', '100px']}
                        rounded="100%"
                      />
                    </Flex>
                    <Skeleton width="180px" height="20px" mt={3} mb={[4, 0]} />
                  </Flex>
                  <Flex
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Flex
                      position="relative"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Skeleton
                        color="white"
                        width={['100px', '180px']}
                        height={['100px', '180px']}
                        rounded="100%"
                      />
                      <Box
                        bgColor="#eef5ff"
                        position="absolute"
                        width={['60px', '100px']}
                        height={['60px', '100px']}
                        rounded="100%"
                      />
                    </Flex>
                    <Skeleton width="180px" height="20px" mt={3} />
                  </Flex>
                </Flex>
              </Flex>
            </Box>
            <Box
              background="#eef5ff"
              width={['260px', '500px']}
              height={['650px', '560px']}
              p="20px"
              borderRadius="8px"
              mx={3}
              my={3}
            >
              <Flex
                flexDir={['column', 'row']}
                justifyContent={['', 'space-between']}
                alignItems="center"
                ml={[0, 6]}
              >
                <Flex
                  flexDir="column"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Flex alignItems="center">
                    <Skeleton
                      width="50px"
                      height="50px"
                      rounded="100%"
                      mr={3}
                    />
                    <Skeleton width="100px" height="20px" />
                  </Flex>
                  <Box>
                    <Skeleton width="210px" height="20px" my={3} />
                    <Skeleton
                      display={['none', 'inherit']}
                      width="130px"
                      height="20px"
                    />
                  </Box>
                </Flex>
                <Flex
                  position="relative"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Skeleton
                    color="white"
                    width={['150px', '200px']}
                    height={['150px', '200px']}
                    rounded="100%"
                  />
                  <Box
                    bgColor="#eef5ff"
                    position="absolute"
                    width={['90px', '100px']}
                    height={['90px', '100px']}
                    rounded="100%"
                  />
                </Flex>
              </Flex>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems={['center', 'start']}
                ml={[0, 6]}
              >
                <Skeleton width="130px" height="40px" mb={6} mt={3} />
                <Flex
                  justifyContent="space-between"
                  flexDir={['column', 'row']}
                >
                  <Flex
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                    mr={[0, '50px']}
                  >
                    <Flex
                      position="relative"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Skeleton
                        color="white"
                        width={['100px', '180px']}
                        height={['100px', '180px']}
                        rounded="100%"
                      />
                      <Box
                        bgColor="#eef5ff"
                        position="absolute"
                        width={['60px', '100px']}
                        height={['60px', '100px']}
                        rounded="100%"
                      />
                    </Flex>
                    <Skeleton width="180px" height="20px" mt={3} mb={[4, 0]} />
                  </Flex>
                  <Flex
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Flex
                      position="relative"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Skeleton
                        color="white"
                        width={['100px', '180px']}
                        height={['100px', '180px']}
                        rounded="100%"
                      />
                      <Box
                        bgColor="#eef5ff"
                        position="absolute"
                        width={['60px', '100px']}
                        height={['60px', '100px']}
                        rounded="100%"
                      />
                    </Flex>
                    <Skeleton width="180px" height="20px" mt={3} />
                  </Flex>
                </Flex>
              </Flex>
            </Box>
            <Box
              background="#eef5ff"
              width={['260px', '500px']}
              height={['650px', '560px']}
              p="20px"
              borderRadius="8px"
            >
              <Flex
                flexDir={['column', 'row']}
                justifyContent={['', 'space-between']}
                alignItems="center"
                ml={[0, 6]}
              >
                <Flex
                  flexDir="column"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Flex alignItems="center">
                    <Skeleton
                      width="50px"
                      height="50px"
                      rounded="100%"
                      mr={3}
                    />
                    <Skeleton width="100px" height="20px" />
                  </Flex>
                  <Box>
                    <Skeleton width="210px" height="20px" my={3} />
                    <Skeleton
                      display={['none', 'inherit']}
                      width="130px"
                      height="20px"
                    />
                  </Box>
                </Flex>
                <Flex
                  position="relative"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Skeleton
                    color="white"
                    width={['150px', '200px']}
                    height={['150px', '200px']}
                    rounded="100%"
                  />
                  <Box
                    bgColor="#eef5ff"
                    position="absolute"
                    width={['90px', '100px']}
                    height={['90px', '100px']}
                    rounded="100%"
                  />
                </Flex>
              </Flex>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems={['center', 'start']}
                ml={[0, 6]}
              >
                <Skeleton width="130px" height="40px" mb={6} mt={3} />
                <Flex
                  justifyContent="space-between"
                  flexDir={['column', 'row']}
                >
                  <Flex
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                    mr={[0, '50px']}
                  >
                    <Flex
                      position="relative"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Skeleton
                        color="white"
                        width={['100px', '180px']}
                        height={['100px', '180px']}
                        rounded="100%"
                      />
                      <Box
                        bgColor="#eef5ff"
                        position="absolute"
                        width={['60px', '100px']}
                        height={['60px', '100px']}
                        rounded="100%"
                      />
                    </Flex>
                    <Skeleton width="180px" height="20px" mt={3} mb={[4, 0]} />
                  </Flex>
                  <Flex
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Flex
                      position="relative"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Skeleton
                        color="white"
                        width={['100px', '180px']}
                        height={['100px', '180px']}
                        rounded="100%"
                      />
                      <Box
                        bgColor="#eef5ff"
                        position="absolute"
                        width={['60px', '100px']}
                        height={['60px', '100px']}
                        rounded="100%"
                      />
                    </Flex>
                    <Skeleton width="180px" height="20px" mt={3} />
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        ) : (
          <Flex justifyContent="center" alignItems="center">
            <ContainerCards>
              {departments.map(
                (dep) =>
                  !!dep.subordinate_departments?.length && (
                    <Card key={dep.departamento_id} background="#eef5ff">
                      <Box w={['300px', 'full']}>
                        {/* <Link to={handleAllocationDepartment(dep.id)}> */}
                        <CardGraphicDepartmentPeriod
                          avatar={dep.responsavel_avatar}
                          name={dep.responsavel}
                          department={{
                            ...dep,
                            center_top_value: dep.alocacao_prevista_geral,
                            center_lower_value: dep.alocacao_geral,
                            subordinate_departments: dep.subordinate_departments.map(
                              (sub) => {
                                return {
                                  ...sub,
                                  center_top_value: sub.alocacao_prevista,
                                  center_lower_value: sub.alocacao,
                                };
                              },
                            ),
                          }}
                          enablePluginDataLabel
                          theme={!dep.disabled_at ? 'alocation' : 'delay'}
                          selectChart={() => ''}
                          date={time}
                          adjust={toggleAdjustExpectedAllocation ? 1 : 0}
                          enableLink
                        />
                      </Box>
                    </Card>
                  ),
              )}
            </ContainerCards>
          </Flex>
        )}

        <Flex mt={5}>
          <Flex alignItems="center">
            <Box width="12px" height="12px" background="#090979" />
            <Text mx={2}>Alocação Prevista</Text>
          </Flex>
          <Flex alignItems="center">
            <Box width="12px" height="12px" background="#00bfff" />
            <Text ml={2}>Alocação Realizada</Text>
          </Flex>
        </Flex>
      </>
    </Box>
  );
};

export default AllocationGraph;
