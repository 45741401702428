import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { FaArrowsAltH, FaTasks } from 'react-icons/fa';
import { Link, useLocation, useParams } from 'react-router-dom';

import {
  Box,
  Flex,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Avatar,
  Progress,
  Divider,
  Badge,
  Tooltip,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import queryString from 'query-string';

import BadgeStatusInfo from '~/modules/dashboard/components/BadgeStatusInfo';
import { AllocationProp } from '~/modules/dashboard/components/BarAllocation';
import ToggleCustom from '~/modules/dashboard/components/ToggleCustom';
import InputChakra from '~/shared/components/InputChakra';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import SectionHeader from '~/shared/components/SectionHeader';
import { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import exportExcel from '~/utils/exportExcel';

import { ProgressCustom, PercentEmployee } from './styles';

import { QueryParamsAllocation } from '..';

interface Department {
  id: string;
  created_at: string;
  updated_at: string;
  alocacao: number;
  alocacao_prevista: number;
  alocacao_geral: number;
  alocacao_prevista_geral: number;
  name: string;
  responsible: string;
  company_id: number;
  responsible_id: number;
  superior_department_id: number;
  funcionarios: Employee[];
}

interface Employee {
  id: number;
  nome: string;
  status_id: number;
  status_nome: string;
  nome_original: string;
  total_alocacao_prevista: number;
  total_alocacao: number;
  total_turnos: number;
  total_alocacao_prevista_percent: number;
  total_alocacao_percent: number;
  alocacao_data: AllocationProp[];
  departamento_id: string;
}

interface Team {
  id: number;
  departamento_superior_id: number;
  empresa_id: number;
  responsavel_id: string;
  name: string;
  created_at: string;
  updated_at: string;
  nome_responsavel: string;
  alocacao: number;
  alocacao_prevista: number;
  funcionarios: Employee[];
}

interface AllocationDepartment {
  departamento: Department;
  equipes: Team[];
}

interface ParamsUrl {
  id?: string;
}

interface Activities {
  category: string;
  attendance_id: number;
  activity: string;
  expected_duration: string;
  activity_start: string;
  activity_end: string;
  real_duration: string;
  date_time_start_planning: string;
  date_time_end_planning: string;
  fatal_date_time: string;
}

interface Attendance {
  attendance_id: number;
  category: string;
  demand: string;
  fantasy: string;
  service_description: string;
  request: string;
  month_request: string;
  competence: string;
  employee: string;
  activity: string;
  date_time_start_planning: string;
  date_time_end_planning: string;
  fatal_date_time: string;
  expected_duration: string;
  real_duration: string;
  activities: Activities;
}

interface FullPerformance {
  category: string;
  demand_amount: number;
  fantasy_name_amount: number;
  employee: string;
  expected_duration: string;
  real_duration: string;
}

interface AllocationEmployee {
  data: Attendance[];
  full_performance: FullPerformance;
}

const AllocationDepartment: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [department, setDepartment] = useState<Department>();
  const [teams, setTeams] = useState<Team[]>([]);
  const location = useLocation();
  const { id: departmentId } = useParams<ParamsUrl>();

  const [loading, setLoading] = useState(true);
  const [loadingExcel, setLoadingExcel] = useState(true);
  const [loadingModal, setLoadingModal] = useState(true);
  // const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [attendance, setAttendance] = useState<Attendance[]>([]);

  const [startDate, setStartDate] = useState(format(new Date(), 'yyyy-MM'));
  const [endDate, setEndDate] = useState();

  const queryParams = useMemo<QueryParamsAllocation>(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const [
    toggleAdjustExpectedAllocation,
    setToggleAdjustExpectedAllocation,
  ] = useState(() => !queryParams?.adjust || queryParams?.adjust === '1');

  const goLink = useMemo(() => {
    // Lembrando que o ajuste da alocação prevista pode alterar
    return `/relatorios/alocacao?start_date=${
      queryParams.start_date
    }&end_date=${queryParams.end_date}&adjust=${
      toggleAdjustExpectedAllocation ? 1 : 0
    }`;
  }, [queryParams, toggleAdjustExpectedAllocation]);

  const tableTitlesExcel = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Categoria',
      },
      {
        title: 'Demanda',
      },
      {
        title: 'Empresa',
      },
      {
        title: 'Cód Atendimento',
      },
      {
        title: 'Descrição Atendimento',
      },
      {
        title: 'Solicitação',
      },
      {
        title: 'Mês de Solicitação',
      },
      {
        title: 'Competência',
      },
      {
        title: 'Atividade',
      },
      {
        title: 'Duração Prevista',
      },
      {
        title: 'Duração Realizada',
      },
      {
        title: 'Inicio da Atividade',
      },
      {
        title: 'Fim da Atividade',
      },
      {
        title: 'Data e Hora Inicio Planejamento',
      },
      {
        title: 'Data e Hora Fim Planejamento',
      },
      {
        title: 'Data e Hora Fatal',
      },
    ];
  }, []);

  /* if (queryParams?.start_date && queryParams?.end_date) {
    format(parseISO(queryParams.start_date), 'YYYY-MM-DD');
    format(parseISO(queryParams.end_date), 'YYYY-MM-DD');
  } */

  useEffect(() => {
    setLoading(true);
    const { CancelToken } = axios;
    const source = CancelToken.source();

    api
      .get<AllocationDepartment>(`/allocation/department/${departmentId}`, {
        cancelToken: source.token,
        params: {
          start_date: queryParams?.start_date,
          end_date: queryParams?.end_date,
          adjust_expected_allocation: toggleAdjustExpectedAllocation ? 1 : 0,
        },
      })
      .then((response) => {
        // ${userId}
        const { departamento, equipes } = response.data;

        // console.log(toggleAdjustExpectedAllocation, queryParams.adjust);
        // console.log(equipes);
        setDepartment({
          ...departamento,
          alocacao: Math.round(departamento?.alocacao),
          alocacao_geral: Math.round(departamento?.alocacao_geral),
          alocacao_prevista: Math.round(departamento?.alocacao_prevista),
          alocacao_prevista_geral: Math.round(
            departamento?.alocacao_prevista_geral,
          ),
        });
        if (equipes?.length) {
          setTeams(
            equipes?.map((team) => {
              return {
                ...team,
                alocacao: Math.round(team.alocacao),
                alocacao_prevista: Math.round(team.alocacao_prevista),
                funcionarios: team.funcionarios.map((func) => {
                  return {
                    ...func,
                    total_alocacao: Math.round(func.total_alocacao),
                    total_alocacao_percent: Math.round(
                      func.total_alocacao_percent,
                    ),
                    total_alocacao_prevista: Math.round(
                      func.total_alocacao_prevista,
                    ),
                    total_alocacao_prevista_percent: Math.round(
                      func.total_alocacao_prevista_percent,
                    ),
                    total_turnos: Math.round(func.total_turnos),
                    alocacao_data: func.alocacao_data?.map((alloc) => {
                      return {
                        ...alloc,
                        alocacao_percent: Math.round(alloc.alocacao_percent),
                        alocacao_prevista_percent: Math.round(
                          alloc.alocacao_prevista_percent,
                        ),
                        total_alocacao: Math.round(alloc.total_alocacao),
                        total_alocacao_prevista: Math.round(
                          alloc.total_alocacao_prevista,
                        ),
                        total_turnos: Math.round(alloc.total_turnos),
                        data_alocacao: format(
                          parseISO(alloc.data_alocacao),
                          "d' 'LLL",
                          { locale: ptBR },
                        ),
                        format_date_allocation: format(
                          parseISO(alloc.data_alocacao),
                          'yyyy-MM-dd',
                        ),
                      };
                    }),
                  };
                }),
              };
            }),
          );
        }
      })
      .finally(() => setLoading(false));

    return () => {
      source.cancel('Operação cancelada pelo usuário');
    };
  }, [queryParams, departmentId, toggleAdjustExpectedAllocation]);

  const indexDefaultDepartment = useMemo(() => {
    // console.log(teams.findIndex((tm) => tm.id === Number(departmentId)));
    return teams.findIndex((tm) => tm.id === Number(departmentId));
  }, [departmentId, teams]);

  const handleExportExcel = useCallback(
    async (data: any) => {
      await api
        .get<AllocationEmployee>(`/reports/performance/allocation/employee/129`)
        .then((response) => {
          setLoadingExcel(true);
          setStartDate(data.start_date);
          setEndDate(data.end_date);
          const resp = response.data;
          // console.log(response.data);
          let name = 'Relatório de Desempenho';

          if (startDate || endDate) {
            const reportStartDate = startDate
              ? format(parseISO(`${startDate}`), 'MM-yyyy')
              : 'indeterminado';
            const reportEndDate = endDate
              ? format(parseISO(`${endDate}`), 'MM-yyyy')
              : 'indeterminado';

            // console.log(reportStartDate, reportEndDate);

            name += ` de ${reportStartDate} a ${reportEndDate}`;
          }
          // exportExcel()

          // let titleSheet = 'RELATORIO_CONSUMO_ESTRUTURA';

          // console.log(startDate, endDate, 'datas');
          exportExcel({
            fileExtension: '.xlsx',
            fileType:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
            title: name,
            name,
            colName: tableTitlesExcel.map((tt) => {
              return tt.title;
            }),
            thead: tableTitlesExcel.map((tt) => {
              return { title: tt.title };
            }),
            titleDataOrder: [
              'category',
              'demand',
              'company',
              'attendance_id',
              'service_description',
              'request',
              'month_request',
              'competence',
              'activity',
              'expected_duration',
              'real_duration',
              'start_activity',
              'end_activity',
              'date_time_start_planning',
              'date_time_end_planning',
              'fatal_date_time',
            ],
            data: resp.data.map((dt) => {
              return {
                category: dt.activities.category.toString(),
                demand: dt.demand.toString(),
                company: dt.fantasy.toString(),
                attendance_id: dt.activities.attendance_id.toString(),
                service_description: dt.service_description.toString(),
                request: dt.request.toString(),
                month_request: dt.month_request.toString(),
                competence: dt.competence.toString(),
                activity: dt.activities.activity.toString(),
                expected_duration: dt.activities.expected_duration.toString(),
                real_duration: dt.activities.real_duration.toString(),
                start_activity: dt.activities.activity_start.toString(),
                end_activity: dt.activities.activity_end.toString(),
                date_time_start_planning: dt.activities.date_time_start_planning.toString(),
                date_time_end_planning: dt.activities.date_time_end_planning.toString(),
                fatal_date_time: dt.activities.fatal_date_time.toString(),
              };
            }),

            // employee: 'funcionario',
            // rangeData: { s: { c: 0, r: 0 }, e: { c: 13, r: 10 } },
            // rangeHeader: 'rangeHeader',
            titleLine: 1,
            enableAutoFilter: true,
          });
        })
        .finally(() => setLoadingExcel(false));
    },
    [endDate, startDate, tableTitlesExcel],
  );

  return (
    <Box>
      <SectionHeader
        title={`Alocação ${department?.name || ''}`}
        pagename="Alocação de equipes"
        loading={!department}
        goBackLink={goLink}
      >
        <ToggleCustom
          defaultIsChecked={toggleAdjustExpectedAllocation}
          toogleAdjust={() =>
            setToggleAdjustExpectedAllocation((state) => !state)
          }
          loading={loading}
          tittle="Ajustar alocação prevista"
          id="adjustExpectedAllocation"
        />
      </SectionHeader>
      <Flex flexDir="column" alignItems={['center', 'start']} mt={-5} mb={3}>
        <Text mb={2} as="b">
          Previsto: {department?.alocacao_prevista_geral}%
        </Text>
        {queryParams.start_date && queryParams.end_date ? (
          <Text textAlign="center" mb={2} as="b">
            Período Analisado:{' '}
            {format(parseISO(queryParams.start_date), 'dd/MM/yyyy')} até{' '}
            {format(parseISO(queryParams.end_date), 'dd/MM/yyyy')}
          </Text>
        ) : (
          ''
        )}

        <Text mb={2} as="b">
          Departamento: {department?.name}
        </Text>
        <Text mb={2} as="b">
          Responsável: {department?.responsible}
        </Text>
      </Flex>
      {loading ? (
        <LoadingAbsolute />
      ) : (
        <>
          {!!teams.length && (
            <Accordion defaultIndex={indexDefaultDepartment} allowToggle>
              {teams.map((team) => {
                return (
                  <AccordionItem key={team.id}>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          {team.name}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    {team.funcionarios
                      .filter(
                        (filter) =>
                          // filter.status_id !== 5 &&
                          filter.status_id !== 5 ||
                          filter.total_alocacao_percent !== 0 ||
                          filter.total_alocacao_prevista_percent !== 0,
                      )
                      .map((func) => {
                        return (
                          <AccordionPanel
                            key={func.id}
                            pb={4}
                            minH="auto"
                            width="full"
                            background="#f8f8f8"
                            my={3}
                            pt={6}
                            borderLeft="solid 2px transparent"
                            _hover={{ borderLeft: 'solid 2px blue' }}
                          >
                            <Flex m={3}>
                              <Flex
                                width="180px"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Avatar name={func.nome} mr={3} />
                                <Flex
                                  flexDir="column"
                                  alignItems="start"
                                  justifyContent="center"
                                  mr={4}
                                >
                                  <Text ml={[0, 3]}>{func.nome}</Text>

                                  <Box>
                                    {func.status_id !== 1 && (
                                      <BadgeStatusInfo
                                        status={func.status_nome}
                                        statusColor={func.nome_original}
                                        size="md"
                                      />
                                    )}
                                    {func.status_id !== 5 && (
                                      <>
                                        <Link
                                          to={`/planejamento?employee=${func.id}`}
                                        >
                                          <Text fontSize="xs">
                                            <Badge
                                              colorScheme="blue"
                                              cursor="pointer"
                                            >
                                              Planejamento
                                            </Badge>
                                          </Text>
                                        </Link>

                                        <Badge
                                          colorScheme="blue"
                                          cursor="pointer"
                                          onClick={onOpen}
                                        >
                                          <Flex
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Box mr="5px">
                                              <FaTasks />
                                            </Box>
                                            <Box>
                                              <Text fontSize="xs">
                                                Desempenho
                                              </Text>
                                            </Box>
                                          </Flex>
                                        </Badge>
                                      </>
                                    )}
                                  </Box>
                                </Flex>
                              </Flex>
                              <Box width="full">
                                <Text fontSize={['sm', 'md']}>
                                  Previsto:{' '}
                                  {func.total_alocacao_prevista_percent}%
                                </Text>
                                <ProgressCustom
                                  rounded="3px"
                                  color="#090979"
                                  size="lg"
                                  value={func.total_alocacao_prevista_percent}
                                />

                                <Text fontSize={['sm', 'md']} mt={2}>
                                  Realizado: {func.total_alocacao_percent}%
                                </Text>
                                <ProgressCustom
                                  rounded="3px"
                                  color="#00BFFF"
                                  size="lg"
                                  value={func.total_alocacao_percent}
                                />
                              </Box>
                            </Flex>
                            <Flex
                              alignItems="center"
                              mt={3}
                              justifyContent={['center', 'start']}
                            >
                              <Flex width={['350px', 'auto']} flexWrap="wrap">
                                <Flex
                                  height="100px"
                                  flexDir="column"
                                  alignItems="center"
                                  justifyContent="center"
                                  mr={3}
                                  mt="-7px"
                                  border="solid 1px transparent"
                                >
                                  <Text width="90px" fontSize={['sm', 'md']}>
                                    Realizado %
                                  </Text>
                                  <Text width="90px" fontSize={['sm', 'md']}>
                                    Previsto %
                                  </Text>
                                </Flex>
                                <Flex flexWrap="wrap">
                                  {func.alocacao_data.map((alloc) => {
                                    return (
                                      <Tooltip
                                        key={alloc.data_alocacao}
                                        label={
                                          !alloc.alocacao_percent
                                            ? 'Não há alocação realizada para exibir'
                                            : ''
                                        }
                                        placement="top"
                                      >
                                        <Link
                                          to={
                                            alloc.alocacao_percent
                                              ? `/relatorios/alocacao/funcionario/${
                                                  func.id
                                                }?department_id=${
                                                  func.departamento_id
                                                }&select_date=${
                                                  alloc.format_date_allocation
                                                }&start_date=${
                                                  queryParams.start_date
                                                }&end_date=${
                                                  queryParams.end_date
                                                }&adjust=${
                                                  toggleAdjustExpectedAllocation
                                                    ? 1
                                                    : 0
                                                }`
                                              : location.search
                                          }
                                        >
                                          <PercentEmployee
                                            flexDir="column"
                                            alignItems="center"
                                          >
                                            <Flex
                                              flexDir="column"
                                              alignItems="center"
                                              justifyContent="center"
                                              m={1}
                                              mt="15px"
                                              width="60px"
                                              height="60px"
                                            >
                                              <Text fontSize={['sm', 'md']}>
                                                {alloc.alocacao_percent}%
                                              </Text>
                                              <Divider
                                                background="#00000085"
                                                height="0.5px"
                                                width="80%"
                                              />
                                              <Text fontSize={['sm', 'md']}>
                                                {
                                                  alloc.alocacao_prevista_percent
                                                }
                                                %
                                              </Text>
                                            </Flex>
                                            <Box width="70px">
                                              <Text
                                                textAlign="center"
                                                fontSize="12px"
                                              >
                                                {alloc.data_alocacao}
                                              </Text>
                                            </Box>
                                          </PercentEmployee>
                                        </Link>
                                      </Tooltip>
                                    );
                                  })}
                                </Flex>
                              </Flex>
                            </Flex>
                          </AccordionPanel>
                        );
                      })}
                  </AccordionItem>
                );
              })}
            </Accordion>
          )}
        </>
      )}

      {/* <BarAllocation id={1} nome= status_id alocacao_data /> */}
      <ModalChakra
        title="Período do Relatório"
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={() => formRef.current?.submitForm()}
        // isLoading={loadingExcel}
        titleButtonConfirm="Solicitar"
      >
        <Form ref={formRef} onSubmit={() => ''}>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Box>
              <InputChakra
                name="start_date"
                type="month"
                label="Período inicio"
                width="250px"
                defaultValue={startDate}
                // disabled={loadingExcel}
              />
            </Box>
            <Box>
              <InputChakra
                name="end_date"
                type="month"
                label="Período fim"
                width="250px"
                // disabled={loadingExcel}
              />
            </Box>
          </Flex>
        </Form>
      </ModalChakra>
    </Box>
  );
};

export default AllocationDepartment;
