import React from 'react';

export default async function askNotificationPermission(): Promise<boolean> {
  // Check if the browser supports notifications
  if (!('Notification' in window)) {
    // console.log('This browser does not support notifications.');
    return false;
  }

  // O usuário ainda não definiu se é para permitir as notificações, solicitamos a permissão
  if (Notification.permission === 'default') {
    const permission = await Notification.requestPermission();

    return permission === 'granted';
  }

  return false;
}
