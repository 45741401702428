import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { DayModifiers } from 'react-day-picker';
import {
  FiEyeOff,
  FiEye,
  FiXOctagon,
  FiChevronDown,
  FiFilter,
  FiDownload,
  FiX,
  FiUsers,
  FiArrowRight,
  FiMessageCircle,
} from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';

import {
  useToast,
  Button,
  Avatar as AVatar,
  Collapse,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Skeleton,
  Text,
  Stack,
  IconButton,
  useDisclosure,
  Box,
  Heading,
  Flex,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  Tooltip,
  Image,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import axios, { CancelTokenSource } from 'axios';
import { isToday, format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { Markup } from 'interweave';
import Pusher from 'pusher-js';
import queryString from 'query-string';
import { mutate } from 'swr';

import { useAuth } from '~/hooks/auth';
import { useTimer } from '~/hooks/timer';
import useCan from '~/hooks/useCan';
import {
  ActivityProps,
  FilterProps,
  FilterActivityProps,
  Pop as PopInterface,
  BeforeActivityProps,
  ExecuteActivityProps,
  MonthPlanningItem,
  ActivityControl,
  DevolutionData,
  Attendance,
} from '~/modules/activityExecution/@types/activity';
import AlertDialog from '~/shared/components/AlertDialog';
import AvatarCustom from '~/shared/components/AvatarCustom';
import CheckedPage from '~/shared/components/CheckedPage';
import InputChakra from '~/shared/components/InputChakra';
import CheckboxChakra from '~/shared/components/InputChakra/CheckboxChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import Loading from '~/shared/components/Loading';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import Header from '~/shared/components/SectionHeader';
import 'react-day-picker/lib/style.css';
import api from '~/shared/services/api';
import askNotificationPermission from '~/utils/askNotificationPermission';

import ActivityDevolution from '../../components/ActivityDevolution';
import ActivityFinished from '../../components/ActivityFinished';
import Allocation from '../../components/Allocation';
import BeforeActivity from '../../components/AttendanceActivities';
import CalendarPlanning from '../../components/CalendarPlanning';
import ChartActivity from '../../components/ChartActivity';
import FilterPlanning from '../../components/FilterPlanning';
import ListActivities from '../../components/ListActivities';
import Pop from '../../components/Pop';
import RunningActivity from '../../components/RunningActivity';
import StructureConsumption from '../../components/StructureConsumption';
import TimerControl from '../../components/TimerControl';
import UserPlanning from '../../components/UserPlanning';
import {
  Container,
  Content,
  ContentSidebar,
  ContainerActivity,
  ActivityHeader,
  ContentLoading,
  ContainerActivityFinished,
  ContainerActivityFinishedHeader,
  FilterParam,
  BtnViewMore,
  PopContent,
  PopFiles,
  Calendar,
} from './styles';

interface AttendanceNextActivities {
  atendimento: Attendance;
  planejamentos: ActivityProps[];
}

interface ActivityDevolution {
  predecessora_id: number;
  plan_id: number;
  funcionario_id: number;
  status_id: number;
  atividade_id: string | number;
  atividade_duracao: string;
  atividade_nome: string;
  atividade_ordem: number;
  funcionario_nome: string;
  available_employee: boolean;
  data_inicio_planejado: string;
  hora_inicio_planejado: string;
  inicio_minimo: string;
  data_fatal: string;
  hora_fatal: string;
  checkactivity: string[];
  checked: boolean;
}

interface ReasonDevolution {
  id: number;
  nome: string;
  value: number;
  label: string;
}

interface QueryParams {
  date?: string;
  employee?: string;
}

interface User {
  id: number;
  name: string;
  email: string;
  avatar?: string;
  avatar_old_name?: string;
  cover?: string;
  cover_old_name?: string;
}

const Planning: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const { user } = useAuth();
  const can = useCan();
  const managerPermission = can('manage-employee-planning');
  const { reset } = useTimer();
  const cancelRef = useRef(null);
  const formDRef = useRef<FormHandles>(null);
  const btnFilterRef = useRef<HTMLButtonElement>(null);

  const queryParams = useMemo<QueryParams>(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();

  const {
    isOpen: isOpenDevol,
    onOpen: onOpenDevol,
    onClose: onCloseDevol,
  } = useDisclosure();

  /* Deve ser apenas o valor inicial o queryParams, pois a alteração de selectedUser
  se da por meio das funções na página */
  const [selectedUser, setSelectedUser] = useState(
    managerPermission && queryParams.employee ? queryParams.employee : user.id,
  );

  /* O primeiro valor do estado, na primeira renderização do elemento, conseguimos obter o valor,
  pois ainda não foi renderizado em tela */
  const isSessionUser = useMemo(
    () => Number(user.id) === Number(selectedUser),
    [selectedUser, user.id],
  );

  const [pageFilter, setPageFilter] = useState(1);
  const [selectedUserName, setSelectedUserName] = useState('Funcionário');
  const [selectedUserAvatar, setSelectedUserAvatar] = useState(
    isSessionUser ? user.avatar : '',
  );
  // const [notFoundUser, setNotFoundUser] = useState(false);
  const [cardSelectedUser, setCardSelectedUser] = useState(false);
  const [cardSelectTeam, setCardSelectTeam] = useState(false);
  const [displayTeamButton, setDisplayTeamButton] = useState(true);
  const [selectedDate, setSelectedDate] = useState(() => {
    return queryParams.date
      ? new Date(Date.parse(`${queryParams.date}T08:00:00-03:00`))
      : new Date();
  });
  const [currentMonth, setCurrentMonth] = useState(() => {
    return queryParams.date
      ? new Date(Date.parse(`${queryParams.date}T08:00:00-03:00`))
      : new Date();
  });
  const [loading, setLoading] = useState(true);
  const [loadingFinishedTotal, setLoadingFinishedTotal] = useState(0);
  const [activityFinishedVisible, setActivityFinishedVisible] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [modalFinishedOpen, setModalFinishedOpen] = useState(false);
  const [modalBeforeActivityControl, setModalBeforeActivityControl] = useState(
    1,
  );
  const [modalPopData, setModalPopData] = useState<PopInterface>();
  const [
    modalBeforeActivityData,
    setModalBeforeActivityData,
  ] = useState<BeforeActivityProps>();
  const [
    modalDevolutionActivityData,
    setModalDevolutionActivityData,
  ] = useState<DevolutionData>();
  const [
    modalDevolutionActivityOpen,
    setModalDevolutionActivityOpen,
  ] = useState(false);
  const [
    currentActivity,
    setCurrentActivity,
  ] = useState<ActivityProps | null>();
  const [runningActivity, setRunningActivity] = useState<ActivityProps | null>(
    null,
  );
  const [returnedActivity, setReturnedActivity] = useState<{
    id: number;
    obs?: string;
  }>();

  const [dataFilter, setDataFilter] = useState<FilterProps | null>(null);

  const [monthPlanning, setMonthPlanning] = useState<MonthPlanningItem[]>([]);

  const [activities, setActivities] = useState<ActivityProps[]>([]);
  const [currentFilterActivity, setFilterCurrentActivity] = useState<
    ActivityProps[]
  >([]);
  const [filterActivityTotal, setFilterActivityTotal] = useState(0);
  const [filterActivityTotalPage, setFilterActivityTotalPage] = useState(0);
  const [activitiesFinished, setActivitiesFinished] = useState<ActivityProps[]>(
    [],
  );
  const [loadingNextActivities, setLoadingNextActivities] = useState(false);
  const [
    loadingActivitiesDevolution,
    setLoadingActivitiesDevolution,
  ] = useState(false);
  const [loadingDevolution, setLoadingDevolution] = useState(false);
  const [activityDevolution, setActivityDevolution] = useState<
    ActivityDevolution[]
  >([]);
  const [
    activityDataDevolution,
    setActivityDataDevolution,
  ] = useState<ActivityProps>({} as ActivityProps);
  const [reasonsDevolution, setReasonsDevolution] = useState<
    ReasonDevolution[]
  >([]);
  // const [refreshMonthDayAvailable, setRefreshMonthDayAvailable] = useState(1);
  const [
    attendanceNextActivities,
    setAttendanceNextActivities,
  ] = useState<AttendanceNextActivities>({} as AttendanceNextActivities);
  const [showNextActivities, setShowNextActivities] = useState(false);

  const addToast = useToast();

  useEffect(() => {
    if (!managerPermission) return;

    if (!queryParams?.employee || queryParams?.employee === user.id) {
      setSelectedUserAvatar(user.avatar);
      setSelectedUserName(user.name);
      return;
    }
    api
      .get<User>(`users/${queryParams.employee}`)
      .then((response) => {
        const { avatar, name } = response.data;

        if (avatar) setSelectedUserAvatar(avatar);
        setSelectedUserName(name);
      })
      .catch(() => {
        addToast({
          position: 'top-right',
          isClosable: true,
          title: 'Funcionário não encontrado',
          description: `Não foi possível encontrar o funcionário selecionado`,
          status: 'error',
        });
      });
  }, [addToast, queryParams, user, managerPermission]);

  // Private channel configuration
  useEffect(() => {
    // console.log(localStorage.getItem('@MyIPAC:token'));

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_ENV || '', {
      cluster: 'sa1',
      // encrypted: true,
      authEndpoint: `${process.env.REACT_APP_API_HOST}/broadcasting/auth`, // Endpoint para autenticação do canal privado
      auth: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@MyIPAC:token')}`,
        },
      },
    });

    const channelUser = `private-user-channel.${user.id}-${user.company_id}`;

    const channel = pusher.subscribe(channelUser);
    // You can bind more channels here like this
    // const channel2 = pusher.subscribe('channel_name2')
    channel.bind('activity-reminder', (/* data: any */) => {
      // console.log(data);

      askNotificationPermission();

      // Exibe a notificação
      if (Notification.permission === 'granted') {
        const text = `Está quase na hora do seu almoço, não se esqueça de pausar ou encerrar a sua atividade!`;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const notification = new Notification('MyIPAC', { body: text });
      }

      // console.log(api.header.authorization);
      // Code that runs when channel listens to a new message
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [user.id, user.company_id]);

  useEffect(() => {
    /* Isso para não recarregar quando alterar o nome default funcionário, para o nome da pessoa,
    pelo método acima quando tiver permissão de gerente */
    if (
      managerPermission &&
      (selectedUserName === 'Funcionário' || selectedUserName === '')
    )
      return () => null;

    const { CancelToken } = axios;
    const source = CancelToken.source();

    api
      .get<MonthPlanningItem[]>('plannings/month', {
        params: {
          year: currentMonth.getFullYear(),
          month: currentMonth.getMonth() + 1,
          employee: selectedUser,
        },
        cancelToken: source.token,
      })
      .then((response) => {
        setMonthPlanning(response.data);
        const findFirstAvailableDay = response.data?.find(
          (monthDay) => monthDay.planning === true,
        );
        if (!findFirstAvailableDay) {
          const formattedMonth = format(currentMonth, 'MMMM', {
            locale: ptBR,
          });
          addToast({
            position: 'top-right',
            isClosable: true,
            title: 'Ooops!!!',
            description: `Nenhuma atividade planejada para o Mês de ${formattedMonth} para o(a) executante ${selectedUserName}. Selecione outro Mês.`,
            status: 'warning',
          });
        }
      });

    return () => {
      /* Após chamar novamente a api, sem que se tenha resposta da primeira solicitação (pendente)
        o pedido anterior é cancelado e se realiza um novo pedido */
      source.cancel('Operação cancelada pelo usuário');
    };

    /* Recarrega sempre que o componente é renderizado ou as dependências selectedUserName,
    refreshMonthDayAvailable substituido por loadingFinishedTotal são atualizadas */
  }, [
    currentMonth,
    selectedUser,
    addToast,
    selectedUserName,
    loadingFinishedTotal,
    managerPermission,
  ]);

  useEffect(() => {
    /* Não há no array de dependência alguma condição que mude após o componente ser renderizado
    porém foi mantido o modelo de cancelToken axios para referência */

    /*  if (typeof cancelToken.current !== typeof undefined) {
      cancelToken.current?.cancel('Operação cancelada pelo usuário');
    }

    const { CancelToken } = axios;
    cancelToken.current = CancelToken.source(); */

    const { CancelToken } = axios;
    const source = CancelToken.source();

    async function loadRunninActivity(): Promise<void> {
      const response = await api.get<ActivityProps>('plannings/running', {
        params: {
          employee: selectedUser,
        },
        cancelToken: source.token,
      });

      const { data } = response;

      if (data.id) {
        const responseControl = await api.get<ActivityControl>(
          `/activity/control/${data.id}`,
          {
            cancelToken: source.token,
          },
        );

        const { pause, created_at } = responseControl.data;

        if (pause) {
          await api.post(
            '/activity/execution',
            {
              planning_id: data.id,
              employee: user.id,
              status_id: 15,
              last_date_time: created_at,
            },
            {
              cancelToken: source.token,
            },
          );

          setActivities((state) =>
            state.map((activity) => {
              if (activity.id === data.id) {
                return {
                  ...activity,
                  situation_id: 17,
                  situation: 'Pausado',
                };
              }
              return activity;
            }),
          );

          setRunningActivity(null);

          addToast({
            position: 'top-right',
            isClosable: true,
            title: 'Atividade pausada!!!',
            description:
              'Você tinha uma atividade em execução, para não ficar com alocação incorreta ela foi pausada.',
            status: 'success',
          });
        }
        if (pause === false) {
          setRunningActivity({
            ...data,
            formatted_start_date:
              data.start_date &&
              format(parseISO(data.start_date), 'dd/MM/yyyy'),
            formatted_start_time:
              data.start_date &&
              format(
                parseISO(`${data.start_date} ${data.start_time}`),
                'HH:mm',
              ),
            formatted_duration:
              data.start_date &&
              format(
                parseISO(`${data.start_date} ${data.duration}`),
                "HH:mm'm'",
              ),
            blocked: !!data.blocked,
          });
        }
      } else {
        setRunningActivity(null);
      }
    }

    loadRunninActivity();

    return () => {
      source.cancel('Operação cancelada pelo usuário');
    };
  }, [selectedUser, user.id, addToast]);

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    setLoading(true);

    async function getPlannings(): Promise<void> {
      // Como não retornamos um erro da rota não está inserido em um try-catch

      const response = await api.get<ActivityProps[]>('plannings', {
        params: {
          date: format(selectedDate, 'yyyy-MM-dd'),
          employee: selectedUser,
        },
        cancelToken: source.token,
      });

      const { data } = response;

      setActivities(
        data.map((activity) => {
          return {
            ...activity,
            formatted_start_date: format(
              parseISO(activity.start_date),
              'dd/MM/yyyy',
            ),
            formatted_start_time: format(
              parseISO(`${activity.start_date} ${activity.start_time}`),
              'HH:mm',
            ),
            formatted_duration: format(
              parseISO(`${activity.start_date} ${activity.duration}`),
              "HH:mm'm'",
            ),
            blocked: !!activity.blocked,
          };
        }),
      );

      const hasRunning = data.find((activity) => activity.situation_id === 15);

      if (hasRunning) {
        setRunningActivity(hasRunning);
      }
    }

    async function getFinishedPlannings(): Promise<void> {
      const response = await api.get<ActivityProps[]>('plannings', {
        params: {
          date: format(selectedDate, 'yyyy-MM-dd'),
          employee: selectedUser,
          only_completed: true,
        },
        cancelToken: source.token,
      });

      const { data } = response;

      setActivitiesFinished(
        data.map((activity) => {
          return {
            ...activity,
            formatted_start_date: format(
              parseISO(activity.start_date),
              'dd/MM/yyyy',
            ),
            formatted_start_time: format(
              parseISO(`${activity.start_date} ${activity.start_time}`),
              'HH:mm',
            ),
            formatted_end_date: format(
              parseISO(activity.end_date),
              'dd/MM/yyyy',
            ),
            formatted_end_time: format(
              parseISO(`${activity.start_date} ${activity.end_time}`),
              'HH:mm',
            ),
            formatted_duration: format(
              parseISO(`${activity.start_date} ${activity.duration}`),
              "HH:mm'm'",
            ),
            blocked: false,
            formatted_real_start_date: activity?.start_real_date
              ? format(parseISO(activity.start_real_date), 'dd/MM/yyyy')
              : undefined,
            formatted_real_end_date: activity?.end_real_date
              ? format(parseISO(activity.end_real_date), 'dd/MM/yyyy')
              : undefined,
            formatted_real_start_time: activity?.start_real_date
              ? format(parseISO(`${activity.start_real_date}`), 'HH:mm')
              : undefined,
            formatted_real_end_time: activity?.end_real_date
              ? format(parseISO(`${activity.end_real_date}`), 'HH:mm')
              : undefined,
          };
        }),
      );
    }

    async function getAllPlannings(): Promise<void> {
      try {
        await getPlannings();
        await getFinishedPlannings();
      } finally {
        setLoading(false);
      }
    }

    getAllPlannings();

    return () => {
      /* Após chamar novamente a api, sem que se tenha resposta da primeira solicitação (pendente)
      o pedido anterior é cancelado e se realiza um novo pedido */
      source.cancel('Operação cancelada pelo usuário');
    };
  }, [selectedDate, selectedUser, user, loadingFinishedTotal]);

  const disabledDays = useMemo(() => {
    const dates = monthPlanning
      .filter((monthDay) => monthDay.planning === false)
      .map((monthDay) => {
        const year = currentMonth.getFullYear();
        const month = currentMonth.getMonth();

        return new Date(year, month, monthDay.day);
      });

    return dates;
  }, [currentMonth, monthPlanning]);

  const handleDateChange = useCallback((day: Date, modifiers: DayModifiers) => {
    if (modifiers.available && !modifiers.disabled) {
      setSelectedDate(day);
    }
  }, []);

  const handleMonthChange = useCallback((month: Date) => {
    setCurrentMonth(month);
  }, []);

  const selectedDateAsText = useMemo(() => {
    return format(selectedDate, "'Dia' dd 'de' MMMM", {
      locale: ptBR,
    });
  }, [selectedDate]);

  const selectedWeekDayAsText = useMemo(() => {
    return format(selectedDate, 'cccc', {
      locale: ptBR,
    });
  }, [selectedDate]);

  const handleNextActivityExecution = useCallback(
    (attendanceId: number) => {
      setLoadingNextActivities(true);

      api
        .get(`plannings/next-execution/${attendanceId}`, {
          params: {
            employee: user.id,
          },
        })
        .then((response) => {
          setShowNextActivities(!!response.data?.planejamentos?.length);
          setAttendanceNextActivities({
            ...response.data,
            atendimento: {
              ...response.data.atendimento,
              demand: {
                id: response.data.atendimento.demand.id,
                name: response.data.atendimento.demand.name,
              },
            },
            planejamentos: response.data?.planejamentos?.map(
              (activity: any) => {
                return {
                  ...activity,
                  attendance_id: response.data.atendimento.id,
                  demand_id: response.data.atendimento.demand.id,
                  demand: response.data.atendimento.demand.name,
                  competence: response.data.atendimento.competencia,
                  company_client_id:
                    response.data.atendimento.company_client.id,
                  company: response.data.atendimento.company_client.name,
                  formatted_start_date: format(
                    parseISO(activity.start_date),
                    'dd/MM/yyyy',
                  ),
                  formatted_start_time: format(
                    parseISO(`${activity.start_date} ${activity.start_time}`),
                    'HH:mm',
                  ),
                  formatted_duration: format(
                    parseISO(`${activity.start_date} ${activity.duration}`),
                    "HH:mm'm'",
                  ),
                  blocked: !!activity.blocked,
                };
              },
            ),
          });
        })
        .finally(() => setLoadingNextActivities(false));
    },
    [user],
  );

  const handleStartActivity = useCallback(
    (activity: ActivityProps) => {
      if (runningActivity?.situation_id === 15) {
        addToast({
          position: 'top-right',
          isClosable: true,
          title: 'Você tem uma atividade em execução!',
          description:
            'Finalize a atividade em execução para executar está atividade.',
          status: 'info',
        });
        return;
      }

      setCurrentActivity(activity);
      setModalConfirmOpen(true);
    },
    [addToast, runningActivity],
  );

  const handleCloseConfirmModal = useCallback(() => {
    setCurrentActivity(null);
    setModalConfirmOpen(false);
  }, []);

  const handleExecuteActivity = useCallback(async () => {
    if (!currentActivity || runningActivity?.situation_id === 15) return;
    setModalConfirmOpen(false);

    // TESTE
    // handleNextActivityExecution(12776);

    const data: ExecuteActivityProps = {
      employee: selectedUser,
      planning_id: currentActivity.id,
      status_id: currentActivity.situation_id,
    };

    try {
      await api.post('activity/execution', { ...data });

      let status_id: number;
      if (
        currentActivity.situation_id === 14 ||
        currentActivity.situation_id === 17
      ) {
        status_id = 15;
      } else {
        status_id = 17;
      }

      const nameSituation = status_id === 15 ? 'Iniciado' : 'Pausado';

      setRunningActivity({
        ...currentActivity,
        situation: nameSituation,
        situation_id: status_id,
      });

      if (dataFilter?.filterActivated) {
        setFilterCurrentActivity((state) =>
          state.map((filterActivity) => {
            return filterActivity.id === currentActivity.id
              ? {
                  ...filterActivity,
                  situation: nameSituation,
                  situation_id: status_id,
                }
              : filterActivity;
          }),
        );
      }

      if (
        showNextActivities &&
        attendanceNextActivities.planejamentos.length > 0
      ) {
        setAttendanceNextActivities((attendanceState) => {
          return {
            ...attendanceState,
            planejamentos: attendanceState.planejamentos.map((planState) => {
              return planState.id === currentActivity.id
                ? {
                    ...planState,
                    situation: nameSituation,
                    situation_id: status_id,
                  }
                : planState;
            }),
          };
        });
      }

      setActivities((state) =>
        state.map((activity) => {
          if (activity.id === currentActivity.id) {
            return {
              ...activity,
              situation: nameSituation,
              situation_id: status_id,
            };
          }
          return activity;
        }),
      );

      if (isSessionUser) {
        mutate(`/allocation/personal/${user.id}`);
      }

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Atividade atualizada!',
        description: `O status da atividade foi alterado para: ${nameSituation}`,
        status: 'info',
      });
    } catch (err) {
      if (err?.response?.status < 500) {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Ooops!!!',
          description:
            err.response.data?.error || 'Ocorreu um erro, tente novamente.',
        });

        return;
      }

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Erro ao executar atividade!',
        description: 'Não foi possível executar a atividade, tente novamente.',
        status: 'error',
      });
    } finally {
      setModalConfirmOpen(false);
    }
  }, [
    attendanceNextActivities,
    showNextActivities,
    currentActivity,
    selectedUser,
    addToast,
    runningActivity,
    dataFilter,
    user.id,
    isSessionUser,
  ]);

  const handleFinishRunningActivity = useCallback(() => {
    setLoadingFinishedTotal((state) => state + 1);

    // o mutate já executado na outra classe runningActivity
    if (isSessionUser && runningActivity?.attendance_id) {
      handleNextActivityExecution(runningActivity.attendance_id);
    }

    if (
      showNextActivities &&
      attendanceNextActivities.planejamentos.length > 0
    ) {
      setAttendanceNextActivities((attendanceState) => {
        return {
          ...attendanceState,
          planejamentos: attendanceState.planejamentos.map((planState) => {
            return planState.id === runningActivity?.id
              ? {
                  ...planState,
                  situation: 'Finalizado',
                  situation_id: 16,
                }
              : planState;
          }),
        };
      });
    }

    if (dataFilter?.filterActivated) {
      setFilterCurrentActivity((state) =>
        state.map((filterActivity) => {
          return filterActivity.id === runningActivity?.id
            ? {
                ...filterActivity,
                situation: 'Finalizado',
                situation_id: 16,
              }
            : filterActivity;
        }),
      );
    }
    // console.log(runningActivity);
    setRunningActivity(null);
  }, [
    isSessionUser,
    handleNextActivityExecution,
    dataFilter,
    attendanceNextActivities.planejamentos,
    showNextActivities,
    runningActivity,
  ]);

  const handleFinishActivity = useCallback((activity: ActivityProps) => {
    setCurrentActivity(activity);

    setModalFinishedOpen(true);
  }, []);

  const handleCloseFinishedModal = useCallback(() => {
    setCurrentActivity(null);

    setModalFinishedOpen(false);
  }, []);

  const handleFinishedActivity = useCallback(async () => {
    if (currentActivity?.id === runningActivity?.id) {
      if (isSessionUser) {
        reset();
      }

      setRunningActivity(null);
    }

    setModalFinishedOpen(false);

    if (!currentActivity) return;

    try {
      const data = {
        planning_id: currentActivity.id,
        employee: selectedUser,
      };

      await api.post('activity/finish', { ...data });

      setLoadingFinishedTotal((state) => state + 1);

      if (dataFilter?.filterActivated) {
        setFilterCurrentActivity((state) =>
          state.map((filterActivity) => {
            return filterActivity.id === currentActivity.id
              ? {
                  ...filterActivity,
                  situation: 'Finalizado',
                  situation_id: 16,
                }
              : filterActivity;
          }),
        );
      }

      if (
        showNextActivities &&
        attendanceNextActivities.planejamentos.length > 0
      ) {
        setAttendanceNextActivities((attendanceState) => {
          return {
            ...attendanceState,
            planejamentos: attendanceState.planejamentos.map((planState) => {
              return planState.id === currentActivity.id
                ? {
                    ...planState,
                    situation: 'Finalizado',
                    situation_id: 16,
                  }
                : planState;
            }),
          };
        });
      }

      if (isSessionUser) {
        mutate(`/allocation/personal/${user.id}`);
        handleNextActivityExecution(currentActivity.attendance_id);
      }

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Atividade finalizada!',
        description: `A atividade '${currentActivity.name}' foi finalizada com sucesso.`,
        status: 'success',
      });
    } catch (err) {
      if (err.response.status < 500) {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Ooops!!!',
          description:
            err.response.data?.error || 'Ocorreu um erro, tente novamente.',
        });

        return;
      }

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Erro ao finalizar atividade!',
        description: 'Não foi possível finalizar a atividade, tente novamente.',
        status: 'error',
      });
    } finally {
      setModalFinishedOpen(false);
    }
  }, [
    showNextActivities,
    attendanceNextActivities,
    currentActivity,
    runningActivity,
    reset,
    addToast,
    selectedUser,
    user.id,
    handleNextActivityExecution,
    dataFilter,
    isSessionUser,
  ]);

  const handleUpdateActivity = useCallback(
    (
      status: 'Pausado' | 'Finalizado' | 'Iniciado' | 'Não Iniciado',
      status_id: number,
    ) => {
      if (!runningActivity) return;

      if (isSessionUser) {
        mutate(`/allocation/personal/${user.id}`);
      }

      setActivities((state) =>
        state.map((activity) => {
          if (activity.id === runningActivity.id) {
            return {
              ...activity,
              situation: status,
              situation_id: status_id,
            };
          }
          return activity;
        }),
      );

      if (
        showNextActivities &&
        attendanceNextActivities.planejamentos.length > 0
      ) {
        setAttendanceNextActivities((attendanceState) => {
          return {
            ...attendanceState,
            planejamentos: attendanceState.planejamentos.map((planState) => {
              return planState.id === runningActivity.id
                ? {
                    ...planState,
                    situation: status,
                    situation_id: status_id,
                  }
                : planState;
            }),
          };
        });
      }

      if (dataFilter?.filterActivated) {
        setFilterCurrentActivity((state) =>
          state.map((filterActivity) => {
            return filterActivity.id === runningActivity.id
              ? {
                  ...filterActivity,
                  situation: status,
                  situation_id: status_id,
                }
              : filterActivity;
          }),
        );
      }

      setRunningActivity({
        ...runningActivity,
        situation: status,
        situation_id: status_id,
      });
    },
    [
      runningActivity,
      user.id,
      isSessionUser,
      showNextActivities,
      attendanceNextActivities,
      dataFilter,
    ],
  );

  const handleSelectNewDate = useCallback((date: Date) => {
    setSelectedDate(date);
    setCurrentMonth(date);
  }, []);

  const handleClearFilter = useCallback(() => {
    setDataFilter(null);
    setFilterCurrentActivity([]);
  }, []);

  const handleDataFilterActivity = useCallback(
    async (data: FilterProps, employeeId?: string): Promise<void> => {
      setLoading(true);

      const filterSe = {
        order: data?.order,
        demand: data?.demand,
        activity: data?.activity,
        competence: data?.competence,
        company_search: data?.company,
        status_1: data?.status_padding ? 14 : null,
        status_2: data?.status_running ? 15 : null,
        status_3: data?.status_finish ? 16 : null,
        status_4: data?.status_paused ? 17 : null,
      };

      api
        .get<FilterActivityProps>('plannings/search', {
          params: {
            page: 1,
            employee: employeeId || selectedUser,
            ...filterSe,
          },
        })
        .then((response) => {
          const { data: filterActivities } = response;

          setFilterCurrentActivity(
            filterActivities.data?.map((activity) => {
              return {
                ...activity,
                formatted_start_date: format(
                  parseISO(activity.start_date),
                  'dd/MM/yyyy',
                ),
                formatted_start_time: format(
                  parseISO(`${activity.start_date} ${activity.start_time}`),
                  'HH:mm',
                ),
                formatted_duration: format(
                  parseISO(`${activity.start_date} ${activity.duration}`),
                  "HH:mm'm'",
                ),
                formatted_real_start_date: activity?.start_real_date
                  ? format(parseISO(activity.start_real_date), 'dd/MM/yyyy')
                  : undefined,
                formatted_real_end_date: activity?.end_real_date
                  ? format(parseISO(activity.end_real_date), 'dd/MM/yyyy')
                  : undefined,
                formatted_real_start_time: activity?.start_real_date
                  ? format(parseISO(`${activity.start_real_date}`), 'HH:mm')
                  : undefined,
                formatted_real_end_time: activity?.end_real_date
                  ? format(parseISO(`${activity.end_real_date}`), 'HH:mm')
                  : undefined,
                blocked: !!activity.blocked,
              };
            }),
          );

          setPageFilter(2);

          setFilterActivityTotalPage(filterActivities.last_page);
          setFilterActivityTotal(filterActivities.total);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [selectedUser],
  );

  const handleDataFilterActivityReadMore = useCallback(
    async (data: FilterProps | null): Promise<void> => {
      setLoading(true);

      const searchParam = data || dataFilter;

      const filterSe = dataFilter
        ? {
            order: searchParam?.order,
            demand: searchParam?.demand,
            competence: searchParam?.competence,
            activity: searchParam?.activity,
            company_search: searchParam?.company,
            status_1: searchParam?.status_padding ? 14 : null,
            status_2: searchParam?.status_running ? 15 : null,
            status_3: searchParam?.status_finish ? 16 : null,
            status_4: searchParam?.status_paused ? 17 : null,
          }
        : {};

      api
        .get<FilterActivityProps>('plannings/search', {
          params: {
            page: pageFilter,
            employee: selectedUser,
            ...filterSe,
          },
        })
        .then((response) => {
          const { data: filterActivities } = response;
          setFilterCurrentActivity([
            ...currentFilterActivity,
            ...filterActivities.data.map((activity) => {
              return {
                ...activity,
                formatted_start_date: format(
                  parseISO(activity.start_date),
                  'dd/MM/yyyy',
                ),
                formatted_start_time: format(
                  parseISO(`${activity.start_date} ${activity.start_time}`),
                  'HH:mm',
                ),
                formatted_duration: format(
                  parseISO(`${activity.start_date} ${activity.duration}`),
                  "HH:mm'm'",
                ),
                formatted_real_start_date: activity?.start_real_date
                  ? format(parseISO(activity.start_real_date), 'dd/MM/yyyy')
                  : undefined,
                formatted_real_end_date: activity?.end_real_date
                  ? format(parseISO(activity.end_real_date), 'dd/MM/yyyy')
                  : undefined,
                formatted_real_start_time: activity?.start_real_date
                  ? format(parseISO(`${activity.start_real_date}`), 'HH:mm')
                  : undefined,
                formatted_real_end_time: activity?.end_real_date
                  ? format(parseISO(`${activity.end_real_date}`), 'HH:mm')
                  : undefined,
                blocked: !!activity.blocked,
              };
            }),
          ]);

          setFilterActivityTotalPage(filterActivities.last_page);
          setPageFilter(pageFilter + 1);
          setFilterActivityTotal(filterActivities.total);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [pageFilter, dataFilter, selectedUser, currentFilterActivity],
  );

  const handleSetFilter = useCallback(
    (data: FilterProps) => {
      setDataFilter(data);
      setPageFilter(1);

      handleDataFilterActivity(data);
    },
    [handleDataFilterActivity],
  );

  /*   const handlePop = useCallback((data: Pop) => {
    setModalPopData(data);
    setModalPopOpen(true);
  }, []); */

  /* const handleClosePop = useCallback(() => {
    setModalPopOpen(false);
  }, []); */

  const handleBeforeActivity = useCallback((data: BeforeActivityProps) => {
    // console.log(data);
    setModalBeforeActivityData({ ...data, onlyPredecessors: true });
    setModalBeforeActivityControl((state) => state + 1);
  }, []);

  const handleAttendanceActivities = useCallback(
    (data: BeforeActivityProps) => {
      setModalBeforeActivityData(data);
      setModalBeforeActivityControl((state) => state + 1);
    },
    [],
  );

  const handleCardSelectedUser = useCallback(
    (id) => {
      setSelectedUser(id);

      if (dataFilter?.filterActivated) {
        setPageFilter(1);
        handleDataFilterActivity(dataFilter, id);
      }

      setSelectedDate(new Date());
    },
    [handleDataFilterActivity, dataFilter],
  );

  const handleDevolution = useCallback(
    async (attendanceId, activityId, activityData) => {
      // console.log(attendanceId, activityId);

      if (!attendanceId || !activityId) {
        return;
      }
      setLoadingActivitiesDevolution(true);
      onOpenDevol();

      try {
        const activitiesDevolutionData = await api.get<ActivityDevolution[]>(
          `plannings/employee-devolution/${attendanceId}/${activityId}`,
        );
        const reasonsDevolutionData = await api.get<ReasonDevolution[]>(
          'devolution/motives',
        );

        setReasonsDevolution(
          reasonsDevolutionData.data?.map((reason) => {
            return {
              ...reason,
              value: reason.id,
              label: reason.nome,
            };
          }),
        );
        setActivityDevolution(activitiesDevolutionData.data);
        setActivityDataDevolution(activityData);
      } catch (err) {
        onCloseDevol();

        if (err.response.status < 500) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Ooops!',
            description:
              err.response.data?.error ||
              'Ocorreu um erro ao carregar atividades predecessoras, tente novamente.',
          });
        } else {
          addToast({
            position: 'top-right',
            isClosable: true,
            title: 'Erro ao carregar atividades predecessoras!',
            description: 'Tente novamente.',
            status: 'error',
          });
        }
      } finally {
        setLoadingActivitiesDevolution(false);
      }
    },
    [addToast, onOpenDevol, onCloseDevol],
  );

  const handleSubmitDevolution = useCallback(
    async (dataActivities) => {
      setLoadingDevolution(true);

      try {
        const formattedData = {
          main: {
            plan_id: activityDataDevolution.id,
            atendimento_id: activityDataDevolution.attendance_id,
            demanda_id: activityDataDevolution.demand_id,
            atividade_id: activityDataDevolution.activity_id,
            funcionario_id: selectedUser,
            status_id: activityDataDevolution.situation_id,
            atividade_duracao: activityDataDevolution.duration,
            data_inicio_planejado: activityDataDevolution.start_date,
            hora_inicio_planejado: activityDataDevolution.start_time,
            inicio_minimo: activityDataDevolution.minimum_start,
            data_fatal: activityDataDevolution.fatal_date || null,
            hora_fatal: activityDataDevolution.fatal_date ? '18:00' : null,
          },
          plannings: dataActivities.plannings?.filter(
            (planning: ActivityDevolution) => {
              return planning.checkactivity?.length > 0;
            },
          ),
        };

        await api.post<ActivityDevolution[]>(
          `plannings/employee-devolution`,
          formattedData,
        );

        setActivityDataDevolution({} as ActivityProps);

        setLoadingDevolution(false);

        setLoadingFinishedTotal((state) => state + 1);

        if (dataFilter?.filterActivated) {
          setFilterCurrentActivity((state) =>
            state.map((filterActivity) => {
              return filterActivity.id === activityDataDevolution.id
                ? {
                    ...filterActivity,
                    situation: 'Finalizado',
                    situation_id: 16,
                  }
                : filterActivity;
            }),
          );
        }

        if (
          showNextActivities &&
          attendanceNextActivities.planejamentos.length > 0
        ) {
          setAttendanceNextActivities((attendanceState) => {
            return {
              ...attendanceState,
              planejamentos: attendanceState.planejamentos.map((planState) => {
                return planState.id === activityDataDevolution.id
                  ? {
                      ...planState,
                      situation: 'Finalizado',
                      situation_id: 16,
                    }
                  : planState;
              }),
            };
          });

          if (isSessionUser) {
            mutate(`/allocation/personal/${user.id}`);
            handleNextActivityExecution(activityDataDevolution.attendance_id);
          }
        }

        let description = '';

        if (runningActivity?.id === activityDataDevolution.id) {
          description =
            'A atividade em execução foi finalizada, pois deve aguardar a finalização das devoluções para poder ser executada novamente';
          setRunningActivity(null);
        }

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Atividade(s) devolvida(s) com sucesso.',
          description,
        });

        onCloseDevol();
      } catch (err) {
        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Ooops!',
            description:
              err.response.data?.error ||
              'Ocorreu um erro ao devolver atividades, tente novamente.',
          });
          setLoadingDevolution(false);

          return;
        }

        addToast({
          position: 'top-right',
          isClosable: true,
          title: 'Erro ao devolver atividades!',
          description: 'Tente novamente.',
          status: 'error',
        });

        setLoadingDevolution(false);
      }
    },
    [
      isSessionUser,
      handleNextActivityExecution,
      attendanceNextActivities,
      dataFilter,
      showNextActivities,
      addToast,
      onCloseDevol,
      selectedUser,
      user,
      activityDataDevolution,
      runningActivity,
    ],
  );

  const handleGetParams = useCallback((): string => {
    const paramsInfoData = {
      ...queryParams,
      date: format(selectedDate, 'yyyy-MM-dd'),
      employee: selectedUser,
    };

    return queryString.stringify(paramsInfoData);
  }, [queryParams, selectedDate, selectedUser]);

  const handlePlanningInfo = useCallback(
    (idPlanning: number, companyHasFolderStructure?: boolean) => {
      /* Validamos apenas se a empresa possui a estrutura padrão de arquivos,
      pois para exibir o botão nos componentes é necessário que a atividade
      possua permissão para anexar arquivos e esteja iniciada */

      if (!companyHasFolderStructure) {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'warning',
          title: 'Não é possível anexar ou verificar arquivos',
          description: 'A empresa não possui a estrutura de pastas no drive',
        });
        return;
      }

      history.push({
        pathname: `/planejamento/${idPlanning}/informacoes`,
        search: handleGetParams(),
      });
    },
    [history, handleGetParams, addToast],
  );

  const handleSendEmail = (planningId: number): void => {
    history.push({
      pathname: `/planejamento/${planningId}/enviar-email`,
      search: handleGetParams(),
    });
  };

  return (
    <Box position="relative">
      <Container>
        {managerPermission ? (
          <>
            <Header
              title={`Olá ${user.name}, Bem vindo(a)!`}
              pagename="Clique no botão ao lado para selecionar o planejamento da sua equipe ou de outro funcionário"
            >
              <Flex
                flexDirection={['column', 'column', 'row']}
                justifyContent="start"
              >
                {displayTeamButton && (
                  <Button
                    size="lg"
                    /* 0, 30em, 48em */
                    mr={[0, 0, 2]}
                    mb={[1, 1, 0]}
                    onClick={() => setCardSelectTeam(!cardSelectTeam)}
                  >
                    <FiUsers />
                    <Text ml={2}>Equipe</Text>
                  </Button>
                )}
                <Button
                  size="lg"
                  onClick={() => setCardSelectedUser(!cardSelectedUser)}
                >
                  <AvatarCustom
                    name={
                      selectedUserName !== 'Funcionário' ? selectedUserName : ''
                    }
                    src={selectedUserAvatar}
                    size="sm"
                    mr={2}
                  />

                  {selectedUserName}
                </Button>
              </Flex>
            </Header>

            <Collapse in={cardSelectTeam}>
              <UserPlanning
                handleUser={(id) => handleCardSelectedUser(id)}
                handleUserName={(name) => setSelectedUserName(name)}
                handleUserAvatar={(avatar) => setSelectedUserAvatar(avatar)}
                close={() => setCardSelectTeam(false)}
                title="Selecione um dos funcionários da sua equipe para ver o planejamento"
                handleDisplayTeamButton={(display) =>
                  setDisplayTeamButton(display)
                }
                onlyTeam
              />
            </Collapse>

            <Collapse in={cardSelectedUser}>
              <UserPlanning
                handleUser={(id) => handleCardSelectedUser(id)}
                handleUserName={(name) => setSelectedUserName(name)}
                handleUserAvatar={(avatar) => setSelectedUserAvatar(avatar)}
                handleDisplayTeamButton={() => setDisplayTeamButton(true)}
                close={() => setCardSelectedUser(false)}
              />
            </Collapse>
          </>
        ) : (
          <Header
            title={`Olá ${user.name}, Bem vindo(a)!`}
            pagename="essa é sua página de planejamento"
          />
        )}

        {!!runningActivity?.id && (
          <RunningActivity
            planningId={runningActivity.id}
            employee={Number(selectedUser)}
            company={user.company_id || 1}
            finish={() => handleFinishRunningActivity()}
            updateActivity={(status, status_id) =>
              handleUpdateActivity(status, status_id)
            }
            isSessionUser={isSessionUser}
            pop={(dataPop) => setModalPopData(dataPop)}
            modalDevolution={(id, obs) => setReturnedActivity({ id, obs })}
            canAttachFiles={runningActivity.attach_file}
            canSendEmail={runningActivity.send_email}
            companyHasFolderStructure={
              runningActivity.company_has_folder_structure
            }
            paramsPushLink={handleGetParams()}
          />
        )}

        {showNextActivities && (
          <Box
            width="full"
            // minHeight="390px"
            rounded="sm"
            borderWidth={1}
            borderColor="gray.50"
            display="flex"
            mb="60px"
            flexWrap="wrap"
            backgroundImage={[
              'linear-gradient(to right, #f6f7ff00, #fbfbff)',
              'linear-gradient(190deg, #f6f7ff00, #fbfbff)',
            ]}
            position="relative"
            p={['10px', '30px']}
          >
            <Flex flexDirection="column" width="full">
              <Flex mb={3} justifyContent="space-between" alignItems="center">
                <Heading size="md" color="yellow.500" textTransform="uppercase">
                  Próximas atividades do atendimento
                </Heading>
                <IconButton
                  aria-label="fechar"
                  title="Fechar"
                  variant="ghost"
                  colorScheme="red"
                  icon={<FiX size={20} />}
                  onClick={() => setShowNextActivities(false)}
                />
              </Flex>
              <StatGroup
                width="full"
                pb={2}
                borderBottomWidth={!loadingNextActivities ? 1 : 0}
                borderColor="gray.100"
                mb={3}
                color="gray.700"
                flexDirection={['column', 'row']}
              >
                {loadingNextActivities ? (
                  <>
                    <Stat>
                      <StatLabel>Atendimento</StatLabel>
                      <Skeleton height="27px" width="120px" mr={4} />
                    </Stat>

                    <Stat>
                      <StatLabel>Demanda</StatLabel>
                      <Skeleton height="27px" width="280px" mr={4} />
                    </Stat>

                    <Stat>
                      <StatLabel>Empresa</StatLabel>
                      <Skeleton height="27px" width="280px" />
                    </Stat>
                  </>
                ) : (
                  <>
                    <Stat>
                      <StatLabel>Atendimento</StatLabel>
                      <StatNumber fontSize={['16px', '18px']}>
                        {attendanceNextActivities.atendimento?.id}
                      </StatNumber>
                    </Stat>

                    <Stat>
                      <StatLabel>Demanda</StatLabel>
                      <StatNumber fontSize={['16px', '18px']}>
                        {attendanceNextActivities.atendimento?.demand.name}
                      </StatNumber>
                    </Stat>

                    <Stat>
                      <StatLabel>Empresa</StatLabel>
                      <StatNumber fontSize={['16px', '18px']}>
                        {
                          attendanceNextActivities.atendimento?.company_client
                            .name
                        }
                      </StatNumber>
                    </Stat>
                  </>
                )}
              </StatGroup>
              {!loadingNextActivities &&
                attendanceNextActivities?.planejamentos?.length > 0 && (
                  <Box width="full">
                    <ListActivities
                      activities={attendanceNextActivities.planejamentos}
                      runningActivity={runningActivity}
                      isSessionUser={isSessionUser}
                      pop={(dataPop) => setModalPopData(dataPop)}
                      attendanceActivities={(data) =>
                        handleAttendanceActivities(data)
                      }
                      beforeActivity={(data) => handleBeforeActivity(data)}
                      planningInfo={(activityId, companyHasFolderStructure) =>
                        handlePlanningInfo(
                          activityId,
                          companyHasFolderStructure,
                        )
                      }
                      lastDevolutionInfo={(id, obs) =>
                        setReturnedActivity({ id, obs })
                      }
                      devolutionActivity={(
                        attendanceId,
                        activityId,
                        activityData,
                      ) =>
                        handleDevolution(attendanceId, activityId, activityData)
                      }
                      startActivity={(activity) =>
                        handleStartActivity(activity)
                      }
                      finishActivity={(activity) =>
                        handleFinishActivity(activity)
                      }
                      sendEmail={(id) => handleSendEmail(id)}
                      titleNoneActivity={`Nenhuma atividade pendente no ${selectedDateAsText}. Selecione outra data!`}
                    />
                  </Box>
                )}
            </Flex>
          </Box>
        )}

        <Content>
          <Calendar>
            {selectedDate && currentMonth && (
              <CalendarPlanning
                disabledDays={disabledDays}
                selectedDays={selectedDate}
                onMonthChange={handleMonthChange}
                onDayClick={handleDateChange}
                initialMonth={currentMonth}
                filterActivated={!!dataFilter?.filterActivated}
              />
            )}
          </Calendar>
          <ContentSidebar>
            <Allocation employee={selectedUser} />

            <ChartActivity
              selectDate={(date) => handleSelectNewDate(date)}
              user_id={selectedUser}
            />

            <StructureConsumption employee={Number(selectedUser)} />
          </ContentSidebar>

          <ContainerActivity>
            <ActivityHeader>
              <div>
                <h3>Suas atividades - Planejamento</h3>
                <strong>
                  {isToday(selectedDate) && (
                    <>
                      Hoje <span />
                    </>
                  )}
                  {selectedDateAsText} <span />
                  {selectedWeekDayAsText}
                </strong>
              </div>
              <Stack isInline mb={5} justifyContent="flex-end">
                <Tooltip
                  hasArrow
                  label="Filtrar planejamento, por: status, demanda, empresa, ou atividade."
                  placement="auto"
                >
                  <IconButton
                    aria-label="Filtros"
                    icon={<FiFilter />}
                    size="lg"
                    borderRadius="md"
                    ml={3}
                    onClick={onOpenFilter}
                    ref={btnFilterRef}
                  />
                </Tooltip>
              </Stack>
            </ActivityHeader>

            <FilterPlanning
              isOpen={isOpenFilter}
              close={onCloseFilter}
              btnRef={btnFilterRef}
              filters={(data) => handleSetFilter(data)}
              data={dataFilter}
              employee={selectedUser}
              company={user.company_id || 1}
              loading={loading}
            />

            {dataFilter?.filterActivated && (
              <FilterParam>
                <div id="removeFilters">
                  <strong>Filtro aplicado</strong>

                  <button type="button" onClick={handleClearFilter}>
                    <FiXOctagon size={16} />

                    <Text>Limpar Filtro</Text>
                  </button>
                </div>

                <div id="filters">
                  <div>
                    {dataFilter?.order_name && (
                      <div>
                        <Text>{dataFilter?.order_name}</Text>
                      </div>
                    )}
                    {dataFilter?.status_padding && (
                      <div>
                        <Text>Não iniciada</Text>
                      </div>
                    )}
                    {dataFilter?.status_running && (
                      <div>
                        <Text>Executando</Text>
                      </div>
                    )}
                    {dataFilter?.status_paused && (
                      <div>
                        <Text>Pausada</Text>
                      </div>
                    )}
                    {dataFilter?.status_finish && (
                      <div>
                        <Text>Finalizada</Text>
                      </div>
                    )}
                  </div>
                  <div>
                    {dataFilter?.company_name && (
                      <div>
                        <Tooltip label={dataFilter?.company_name}>
                          <Text isTruncated>{dataFilter?.company_name}</Text>
                        </Tooltip>
                      </div>
                    )}
                    {dataFilter?.demand_name && (
                      <div>
                        <Tooltip label={dataFilter?.demand_name}>
                          <Text isTruncated>{dataFilter?.demand_name}</Text>
                        </Tooltip>
                      </div>
                    )}
                    {dataFilter?.activity_name && (
                      <div>
                        <Tooltip label={dataFilter?.activity_name}>
                          <Text isTruncated>{dataFilter?.activity_name}</Text>
                        </Tooltip>
                      </div>
                    )}
                    {dataFilter?.competence_value && (
                      <div>
                        <Tooltip label={dataFilter?.competence_value}>
                          <Text isTruncated>
                            {dataFilter?.competence_value}
                          </Text>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>

                <small>
                  {loading ? 0 : filterActivityTotal} registro(s) encontrado(s)
                </small>
              </FilterParam>
            )}
            {/* O zIndex 3 é necessário pois o nosso slider de opções de atividade
            possui zIndex 2 */}
            {loading && (
              <ContentLoading style={{ zIndex: 3 }}>
                <Loading />
              </ContentLoading>
            )}

            {dataFilter?.filterActivated && (
              <>
                <ListActivities
                  activities={currentFilterActivity}
                  runningActivity={runningActivity}
                  isSessionUser={isSessionUser}
                  pop={(dataPop) => setModalPopData(dataPop)}
                  attendanceActivities={(data) =>
                    handleAttendanceActivities(data)
                  }
                  beforeActivity={(data) => handleBeforeActivity(data)}
                  planningInfo={(activityId, companyHasFolderStructure) =>
                    handlePlanningInfo(activityId, companyHasFolderStructure)
                  }
                  lastDevolutionInfo={(id, obs) =>
                    setReturnedActivity({ id, obs })
                  }
                  devolutionActivity={(
                    attendanceId,
                    activityId,
                    activityData,
                  ) => handleDevolution(attendanceId, activityId, activityData)}
                  startActivity={(activity) => handleStartActivity(activity)}
                  finishActivity={(activity) => handleFinishActivity(activity)}
                  sendEmail={(id) => handleSendEmail(id)}
                  titleNoneActivity="Nenhuma atividade encontrada para o filtro aplicado. Tente selecionar outros status ou desmarque os existentes."
                />

                {(pageFilter <= filterActivityTotalPage ||
                  !filterActivityTotalPage) &&
                  currentFilterActivity.length > 0 && (
                    <BtnViewMore
                      type="button"
                      onClick={() => handleDataFilterActivityReadMore(null)}
                    >
                      {loading ? 'Carregando...' : 'Ver mais'}
                      <FiChevronDown size={16} />
                    </BtnViewMore>
                  )}
              </>
            )}

            {!dataFilter?.filterActivated && (
              <>
                <ListActivities
                  activities={activities}
                  runningActivity={runningActivity}
                  isSessionUser={isSessionUser}
                  pop={(dataPop) => setModalPopData(dataPop)}
                  attendanceActivities={(data) =>
                    handleAttendanceActivities(data)
                  }
                  beforeActivity={(data) => handleBeforeActivity(data)}
                  planningInfo={(activityId, companyHasFolderStructure) =>
                    handlePlanningInfo(activityId, companyHasFolderStructure)
                  }
                  lastDevolutionInfo={(id, obs) =>
                    setReturnedActivity({ id, obs })
                  }
                  devolutionActivity={(
                    attendanceId,
                    activityId,
                    activityData,
                  ) => handleDevolution(attendanceId, activityId, activityData)}
                  startActivity={(activity) => handleStartActivity(activity)}
                  finishActivity={(activity) => handleFinishActivity(activity)}
                  sendEmail={(id) => handleSendEmail(id)}
                  titleNoneActivity={`Nenhuma atividade pendente no ${selectedDateAsText}. Selecione outra data!`}
                />
                {!!activitiesFinished.length && (
                  <>
                    <ContainerActivityFinishedHeader>
                      <h5>Atividades finalizadas</h5>

                      <button
                        type="button"
                        onClick={() =>
                          setActivityFinishedVisible(!activityFinishedVisible)
                        }
                      >
                        {activityFinishedVisible ? (
                          <>
                            <FiEyeOff size={16} /> <span>ocultar</span>
                          </>
                        ) : (
                          <>
                            <FiEye size={16} /> <span>visualizar</span>
                          </>
                        )}
                      </button>
                    </ContainerActivityFinishedHeader>

                    <ContainerActivityFinished
                      className={activityFinishedVisible ? '' : 'invisible'}
                    >
                      {activitiesFinished.map((activity) => (
                        <ActivityFinished
                          data={activity}
                          pop={(dataPop) => setModalPopData(dataPop)}
                          modalDevolution={(id, obs) =>
                            setReturnedActivity({ id, obs })
                          }
                          viewPlanningInfo={() =>
                            handlePlanningInfo(
                              activity.id,
                              activity.company_has_folder_structure,
                            )
                          }
                          key={activity.id}
                        />
                      ))}
                    </ContainerActivityFinished>

                    {!activityFinishedVisible && (
                      <ContainerActivityFinished>
                        <button
                          type="button"
                          className="hidden-items"
                          onClick={() => setActivityFinishedVisible(true)}
                        >
                          <FiEyeOff size={40} />
                          <span>
                            {activitiesFinished.length} atividade(s) oculta(s),
                            clique para visualizar!
                          </span>
                        </button>
                      </ContainerActivityFinished>
                    )}
                  </>
                )}
              </>
            )}
          </ContainerActivity>
        </Content>
      </Container>

      <AlertDialog
        title="Executar Atividade?"
        isOpen={modalConfirmOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleCloseConfirmModal}
        onSubmit={handleExecuteActivity}
        colorScheme="blue"
        submitButtonColor="blue.500"
      >
        <Text fontSize="md">{currentActivity?.name}</Text>
      </AlertDialog>

      <AlertDialog
        title="Finalizar Atividade?"
        description={`Está pronto para finalizar a atividade: ${currentActivity?.name}`}
        isOpen={modalFinishedOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleCloseFinishedModal}
        onSubmit={handleFinishedActivity}
        colorScheme="green"
        submitButtonColor="green.500"
      />
      {modalPopData && (
        <Pop
          data={modalPopData}
          onClosePop={() => setModalPopData(undefined)}
        />
      )}

      <ModalChakra
        title="Devolução de Atividades"
        onClose={onCloseDevol}
        isOpen={isOpenDevol}
        onSubmit={() => formDRef.current?.submitForm()}
        isLoading={loadingDevolution}
      >
        {loadingActivitiesDevolution ? (
          <Loading />
        ) : (
          <Box mb={4} textAlign="center" p={2}>
            <Text mb={4}>
              Selecione as atividades abaixo que serão devolvidas e o motivo da
              sua devolução:
            </Text>
            <Form onSubmit={handleSubmitDevolution} ref={formDRef}>
              {activityDevolution?.length &&
                activityDevolution.map((activity, index) => {
                  return (
                    <Box
                      borderColor={activity.checked ? 'green.500' : ''}
                      bg={activity.checked ? 'green.50' : ''}
                      key={activity.plan_id}
                      borderWidth={1}
                      rounded="sm"
                      mb={2}
                      width="full"
                      px={2}
                      pt={2}
                    >
                      <Box display="none">
                        <InputChakra
                          type="hidden"
                          name={`plannings[${index}].plan_id`}
                          defaultValue={activity.plan_id}
                        />
                        <InputChakra
                          type="hidden"
                          name={`plannings[${index}].atividade_id`}
                          defaultValue={activity.atividade_id}
                        />
                        <InputChakra
                          type="hidden"
                          name={`plannings[${index}].funcionario_id`}
                          defaultValue={activity.funcionario_id}
                        />
                        <InputChakra
                          type="hidden"
                          name={`plannings[${index}].atividade_duracao`}
                          defaultValue={activity.atividade_duracao}
                        />
                        <InputChakra
                          type="hidden"
                          name={`plannings[${index}].data_inicio_planejado`}
                          defaultValue={activity.data_inicio_planejado}
                        />
                        <InputChakra
                          type="hidden"
                          name={`plannings[${index}].hora_inicio_planejado`}
                          defaultValue={activity.hora_inicio_planejado}
                        />
                        <InputChakra
                          type="hidden"
                          name={`plannings[${index}].inicio_minimo`}
                          defaultValue={activity.inicio_minimo}
                        />
                        <InputChakra
                          type="hidden"
                          name={`plannings[${index}].data_fatal`}
                          defaultValue={activity.data_fatal}
                        />
                        <InputChakra
                          type="hidden"
                          name={`plannings[${index}].hora_fatal`}
                          defaultValue={activity.hora_fatal}
                        />
                      </Box>
                      <CheckboxChakra
                        name={`plannings[${index}].checkactivity`}
                        onChange={() => {
                          setActivityDevolution((state) =>
                            state.map((activityStateDevolution) => {
                              return Number(activityStateDevolution.plan_id) ===
                                Number(activity.plan_id)
                                ? {
                                    ...activityStateDevolution,
                                    checked: !activityStateDevolution.checked,
                                  }
                                : activityStateDevolution;
                            }),
                          );
                        }}
                        options={[
                          {
                            id: String(activity.plan_id),
                            value: String(activity.plan_id),
                            label: activity.atividade_nome,
                            isDisabled: !activity.available_employee,
                          },
                        ]}
                      />
                      <Flex
                        flexDir="column"
                        textAlign="left"
                        width="full"
                        my={2}
                      >
                        <Text>Executante</Text>
                        <Heading size="md">{activity.funcionario_nome}</Heading>
                      </Flex>

                      {!activity.available_employee ? (
                        <Text color="red.500" mb={2}>
                          (Executante indisponível, solicite ao gerente para
                          alterar o executante desta atividade)
                        </Text>
                      ) : (
                        <SelectChakra
                          name={`plannings[${index}].motivo_id`}
                          options={reasonsDevolution}
                        />
                      )}
                    </Box>
                  );
                })}
            </Form>
          </Box>
        )}
      </ModalChakra>

      {modalBeforeActivityData?.attendance && (
        <BeforeActivity
          attendance={modalBeforeActivityData?.attendance}
          data={modalBeforeActivityData}
          controlModal={modalBeforeActivityControl}
          onlyPredecessors={!!modalBeforeActivityData.onlyPredecessors}
          listPredecessors
        />
      )}
      {returnedActivity && (
        <ActivityDevolution
          id={returnedActivity.id}
          obs={returnedActivity.obs}
          onCloseDevolution={() => setReturnedActivity(undefined)}
        />
      )}
    </Box>
  );
};

export default Planning;
