import React, { useCallback, ReactNode } from 'react';

import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogProps,
  Button,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogCloseButton,
} from '@chakra-ui/react';

interface Props extends Omit<AlertDialogProps, 'children'> {
  title: string;
  description?: string;
  titleButtonCancel?: string;
  titleButtonConfirm?: string;
  VariantColor?: string;
  submitButtonColor?: string;
  onSubmit(): void;
  onCancel?(): void;
  isLoading?: boolean;
  children?: ReactNode;
  htmlContent?: boolean;
}

const AlertDialog: React.FC<Props> = ({
  title,
  titleButtonCancel,
  titleButtonConfirm,
  htmlContent = false,
  description,
  VariantColor,
  onSubmit,
  isLoading = false,
  isOpen,
  onClose,
  onCancel,
  leastDestructiveRef,
  children,
  submitButtonColor,
  ...rest
}) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <ChakraAlertDialog
      preserveScrollBarGap
      isOpen={isOpen}
      leastDestructiveRef={leastDestructiveRef}
      onClose={onClose}
      {...rest}
    >
      <AlertDialogOverlay />
      <AlertDialogContent borderRadius="sm">
        <AlertDialogHeader fontSize="lg" fontWeight="bold" pr={9}>
          {title}
        </AlertDialogHeader>
        <AlertDialogCloseButton />

        {htmlContent ? (
          <AlertDialogBody
            dangerouslySetInnerHTML={{ __html: description || '' }}
          />
        ) : (
          <AlertDialogBody>{description || children}</AlertDialogBody>
        )}

        <AlertDialogFooter>
          {!isLoading && (
            <Button
              variant="ghost"
              onClick={() => {
                onClose();
                onCancel && onCancel();
              }}
            >
              {titleButtonCancel || 'Cancelar'}
            </Button>
          )}
          <Button
            colorScheme={VariantColor || 'red'}
            onClick={handleSubmit}
            isLoading={isLoading}
            isDisabled={isLoading}
            bg={submitButtonColor || 'red.500'}
            ml={3}
          >
            {titleButtonConfirm || 'Confirmar'}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </ChakraAlertDialog>
  );
};

export default AlertDialog;
